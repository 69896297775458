/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface JiraIssueDTO {
  components?: Array<string>;
  creationDate?: string;
  description?: string;
  doer?: string;
  fixVersions?: Array<string>;
  issueId?: string;
  issueType?: JiraIssueDTO.IssueTypeEnum;
  linkToAssana?: string;
  linksToAttachments?: Array<string>;
  priority?: JiraIssueDTO.PriorityEnum;
  status?: string;
  summary?: string;
}
export namespace JiraIssueDTO {
  export type IssueTypeEnum = 'BUG' | 'ENHANCEMENT' | 'NEW_REQUEST' | 'REQUEST' | 'SUPPORT';
  export const IssueTypeEnum = {
    Bug: 'BUG' as IssueTypeEnum,
    Enhancement: 'ENHANCEMENT' as IssueTypeEnum,
    NewRequest: 'NEW_REQUEST' as IssueTypeEnum,
    Request: 'REQUEST' as IssueTypeEnum,
    Support: 'SUPPORT' as IssueTypeEnum,
  };
  export type PriorityEnum = 'BLOCKER' | 'HIGH' | 'LOW' | 'MEDIUM' | 'MINOR';
  export const PriorityEnum = {
    Blocker: 'BLOCKER' as PriorityEnum,
    High: 'HIGH' as PriorityEnum,
    Low: 'LOW' as PriorityEnum,
    Medium: 'MEDIUM' as PriorityEnum,
    Minor: 'MINOR' as PriorityEnum,
  };
}
