import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CompareDatePipe } from '@shared/pipes/compare-date.pipe';
import { GetAccountsPipe } from '@shared/pipes/get-accounts.pipe';
import { GetMainBankAccountPipe } from '@shared/pipes/get-main-bank-account.pipe';
import { GetTwelveDataValueWithSuffixPipe } from '@shared/pipes/get-twelve-data-value-with-suffix.pipe';
import { GetUniqOrCommonArrayItemsPipe } from '@shared/pipes/get-uniq-or-common-array-items.pipe';
import { MediaViewSrcTypePipePipe } from '@shared/pipes/media-view-src-type.pipe';
import { SetTimeToDatePipe } from '@shared/pipes/set-time-to-date.pipe';
import { AbsoluteNumberPipe } from './abs-number.pipe';
import { AvgNumberPipe } from './avg-number.pipe';
import { BooleanToWordPipe } from './boolean-to-word.pipe';
import { CamelCasePipe } from './camel-case.pipe';
import { CcySymbolPipe } from './ccy-symbol.pipe';
import { CcyWithThousandSuffixPipe } from './ccy-with-thousand-suffix.pipe';
import { CcyPipe } from './ccy.pipe';
import { ChangeDatePipe } from './change-date.pipe';
import { CleanHtmlTagsPipe } from './clean-html-tags.pipe';
import { ClonePipe } from './clone.pipe';
import { CombineLatestPipe } from './combine-latest.pipe';
import { CommonCasePipe } from './common-case.pipe';
import { ConvertFileToBase64Pipe } from './convert-file-to-base64.pipe';
import { CoverPreviewForAssetTypePipe } from './cover-preview-for-asset-type.pipe';
import { AppDatePipe } from './date.pipe';
import { DurationPipe } from './duration.pipe';
import { FieldValueForTypePipe } from './field-value-for-type.pipe';
import { FileSizePipe } from './file-size.pipe';
import { FilterArrayPipe } from './filter-array.pipe';
import { FindInArrayPipe } from './find-in-array.pipe';
import { FormControlKeysArrayPipe } from './form-control-keys-array.pipe';
import { GetCcySvgSrcPipe } from './get-ccy-svg-src.pipe';
import { GetChangesPipe } from './get-changes.pipe';
import { GetEntityFieldEnumValuesPipe } from './get-entity-field-enum-values.pipe';
import { GetInstrumentImgSrcPipe } from './get-instrument-img-src.pipe';
import { GetInstrumentPriceByMicCodePipe } from './get-instrument-price-by-miccode.pipe';
import { GetInstrumentPriceBySocketPipe } from './get-instrument-price-by-socket.pipe';
import { GetManagersByCompanyPipe } from './get-managers-by-company.pipe';
import { GetNetAmountPortfolioCcyPipe } from './get-net-amount-portfolio-ccy.pipe';
import { GetOmnibusBankAccountsPipe } from './get-omnibus-bank-accounts.pipe';
import { GetPictureDimensionsPipe } from './get-picture-dimensions.pipe';
import { GetSelectorFieldValuesPipe } from './get-selector-field-values.pipe';
import { GetThousandValueWithSuffixPipe } from './get-thousand-value-with-suffix.pipe';
import { GetTtcaBankAccountsPipe } from './get-ttca-bank-accounts.pipe';
import { GetValuePipe } from './get-value.pipe';
import { HasAuthoritiesPipe } from './has-authorities.pipe';
import { HasDisabledAuthoritiesPipe } from './has-disabled-authorities.pipe';
import { HasErrorPipe } from './has-error.pipe';
import { HasRoleCodesPipe } from './has-role-codes.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { IsNotNullPipe } from './is-not-null.pipe';
import { JoinArrayPipe } from './join-array.pipe';
import { KebabCasePipe } from './kebab-case.pipe';
import { LoadMediaAndGetObjectUrlPipe } from './load-media-and-get-object-url.pipe';
import { LogPipe } from './log.pipe';
import { MergeChangesPipe } from './merge-changes.pipe';
import { NeedSubscriptionForAuthoritiesPipe } from './need-subscription-for-authorities.pipe';
import { NumberPipe } from './number.pipe';
import { PercentPipe } from './percent.pipe';
import { PeriodPipe } from './period.pipe';
import { SafeHtmlPipe } from './safe-html';
import { SliceArrayPipe } from './slice-array.pipe';
import { SnakeUppercasePipe } from './snake-uppercase.pipe';
import { SplitArrayPipe } from './split-array.pipe';
import { SumArrayPipe } from './sum-array.pipe';
import { TextTransformPipe } from './text-transform.pipe';
import { TimePipe } from './time.pipe';
import { ToDatePipe } from './to-date.pipe';
import { ToNumberPipe } from './to-number.pipe';
import { ToStringPipe } from './to-string.pipe';
import { TypedFormGroupPipe } from './typed-form-group.pipe';

const PIPES = [
  AbsoluteNumberPipe,
  AppDatePipe,
  AvgNumberPipe,
  BooleanToWordPipe,
  CamelCasePipe,
  CcyPipe,
  GetCcySvgSrcPipe,
  CcySymbolPipe,
  CcyWithThousandSuffixPipe,
  ChangeDatePipe,
  CleanHtmlTagsPipe,
  ClonePipe,
  CommonCasePipe,
  CompareDatePipe,
  CoverPreviewForAssetTypePipe,
  DurationPipe,
  FieldValueForTypePipe,
  FileSizePipe,
  FilterArrayPipe,
  FormControlKeysArrayPipe,
  SetTimeToDatePipe,
  GetChangesPipe,
  HasErrorPipe,
  GetEntityFieldEnumValuesPipe,
  GetInstrumentPriceBySocketPipe,
  GetInstrumentPriceByMicCodePipe,
  GetInstrumentImgSrcPipe,
  GetMainBankAccountPipe,
  GetManagersByCompanyPipe,
  GetNetAmountPortfolioCcyPipe,
  GetOmnibusBankAccountsPipe,
  GetThousandValueWithSuffixPipe,
  GetTtcaBankAccountsPipe,
  GetTwelveDataValueWithSuffixPipe,
  GetUniqOrCommonArrayItemsPipe,
  HasAuthoritiesPipe,
  HasDisabledAuthoritiesPipe,
  HasRoleCodesPipe,
  IsNotNullPipe,
  IsEmptyPipe,
  JoinArrayPipe,
  KebabCasePipe,
  LoadMediaAndGetObjectUrlPipe,
  LogPipe,
  MediaViewSrcTypePipePipe,
  MergeChangesPipe,
  NeedSubscriptionForAuthoritiesPipe,
  NumberPipe,
  PercentPipe,
  PeriodPipe,
  SafeHtmlPipe,
  SliceArrayPipe,
  SnakeUppercasePipe,
  SplitArrayPipe,
  SumArrayPipe,
  TextTransformPipe,
  TimePipe,
  ToDatePipe,
  ToNumberPipe,
  ToStringPipe,
  TypedFormGroupPipe,
  CombineLatestPipe,
  ConvertFileToBase64Pipe,
  GetPictureDimensionsPipe,
  GetAccountsPipe,
  GetValuePipe,
  GetSelectorFieldValuesPipe,
  FindInArrayPipe,
];

@NgModule({
  declarations: [...PIPES],
  exports: [...PIPES],
  imports: [CommonModule],
})
export class PipesModule {}
