import { Injectable } from '@angular/core';
import {
  addBusinessDays,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addQuarters,
  addSeconds,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfToday,
  endOfYear,
  getHours,
  getMinutes,
  getSeconds,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfWeek,
  startOfYear,
  subBusinessDays,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subQuarters,
  subSeconds,
  subWeeks,
  subYears,
} from 'date-fns';
import { format } from 'date-fns-tz';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { PeriodEnum } from '@shared/types/period-enum';
import { DateRangePeriod } from '@ui/input/datepicker/daterange-picker.types';
import { AppInjector } from 'app/config/app-injector';

export const UNIX_EPOCH_START_DATE: string = '1970-01-01';

export const DateFormats = {
  Date: 'yyyy-MM-dd',
  DateDots: 'yyyy.MM.dd',
  MonthDate: 'MMM. d',
  DateTime: `yyyy-MM-dd HH:mm:ss`,
  Time: `HH:mm:ss`,
};

export type DateUnit =
  | 'Seconds'
  | 'Minutes'
  | 'Hours'
  | 'Days'
  | 'BusinessDays'
  | 'Weeks'
  | 'Months'
  | 'Years'
  | 'Quarter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(Date.prototype as any).toJSON_Original = Date.prototype.toJSON;

Date.prototype.toJSON = function () {
  const dateHelper = AppInjector.Injector.get(DateHelper);
  const date = dateHelper.parse(this);

  if (getHours(date) === 0 && getMinutes(date) === 0 && getSeconds(date) === 0) {
    return dateHelper.format(date, DateFormats.Date);
  } else {
    return this.toJSON_Original();
  }
};

@Injectable({ providedIn: 'root' })
export class DateHelper {
  private _epoch: Date;

  constructor() {
    this._epoch = this.resetTime(new Date(UNIX_EPOCH_START_DATE));
  }

  /**
   * Get UNIX timestamp from Date object.
   * The UNIX timestamp is an integer that represents the number of milliseconds elapsed since January 1 1970
   * @param date
   * @returns seconds number
   */
  public getTimestamp(date: Date): number {
    return date.getTime();
  }

  /**
   * Get Date from UNIX timestamp.
   * @param timestamp The UNIX timestamp number in milliseconds is an integer that represents the number of milliseconds elapsed since January 1 1970
   * @returns Date object
   */
  public getDateFromTimestamp(timestamp: number): Date {
    return new Date(timestamp);
  }

  /**
   * Конвертирует переданную дату в строку
   * @param date дата любого типа (Date | string) для конвертации
   * @param dateFormat (Optional) форматирование даты, кастомный формат или тип из константы DateFormats, yyyy-MM-dd по умолчанию.
   * @returns строка форматированной даты
   */
  public format(date: Date | string, dateFormat: string = DateFormats.Date): string | null {
    date = this.parse(date);

    return date ? format(date, dateFormat) : null;
  }

  /**
   * Конвертирует переданную дату в UTC строку
   *
   * @param date дата любого типа (Date | string) для конвертации
   * @param showTime (Optional) вернется строка со временем или нет. true по умолчанию.
   * @returns строка даты в UTC формате
   */
  public formatAsUTC(date: Date | string, showTime: boolean = true): string | null {
    date = this.parse(date);

    if (date) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const utcString: string = (date as any).toJSON_Original();
      return showTime ? utcString : utcString.substring(0, 10);
    } else {
      return null;
    }
  }

  /**
   * Конвертирует переданную дату в UTC или обычную строку с учетом времени в перданной date,
   * Если разница между date и now (текущей датой) больше 1 дня, date возвращается как строка даты как есть без времени.
   * Если разница между date и now (текущей датой) меньше 1 дня, date берется со временем и возвращается дата как UTC строка без времени.
   *
   * @param date дата любого типа (Date | string) для конвертации
   * @returns строка даты в обычном или UTC формате
   */
  public formatAsOptionalUTC(date: Date | string): string | null {
    date = this.parse(date);

    if (date) {
      if (this.difference(date, this.now(), 'Days') !== 0) {
        return this.format(date);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const utcString: string = (date as any).toJSON_Original();
        return utcString.substring(0, 10);
      }
    } else {
      return null;
    }
  }

  /**
   * Создает объект локальной Date. Парсит переданную дату и создает объект с локальной Date
   * @param date дата любого типа (Date | string) для парсинга
   * @param customDateFormat (Optional) формат для парсинга, указывает в каком формате дата, если типы date был string
   * @returns объект Date
   */
  public parse(date: Date | string, customDateFormat?: string): Date | null {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      if (customDateFormat) {
        const result = parse(date, customDateFormat, new Date());

        if (!isValid(result)) {
          return null;
        }

        return result;
      } else {
        const result = parseISO(date);

        if (!isValid(result)) {
          return null;
        }

        return result;
      }
    } else if (date instanceof Date) {
      return date;
    } else {
      return null;
    }
  }

  public now(resetTime: boolean = true): Date {
    return resetTime ? this.resetTime(new Date()) : new Date();
  }

  public epoch(): Date {
    return this._epoch;
  }

  public resetTime(date: Date | string): Date | null {
    date = this.parse(date);
    return date ? startOfDay(date) : null;
  }

  public startOf(
    type: 'Today' | 'Day' | 'Week' | 'Month' | 'Year' | 'Quarter',
    refDate: Date = new Date(),
  ): Date {
    switch (type) {
      case 'Today':
        return startOfToday();

      case 'Day':
        return startOfDay(refDate);

      case 'Week':
        return startOfWeek(refDate);

      case 'Month':
        return startOfMonth(refDate);

      case 'Year':
        return startOfYear(refDate);

      case 'Quarter':
        return startOfQuarter(refDate);

      default:
        return new Date();
    }
  }

  public endOf(
    type: 'Today' | 'Day' | 'Month' | 'Year' | 'Quarter',
    refDate: Date = new Date(),
  ): Date {
    switch (type) {
      case 'Today':
        return endOfToday();

      case 'Day':
        return endOfDay(refDate);

      case 'Month':
        return endOfMonth(refDate);

      case 'Year':
        return endOfYear(refDate);

      case 'Quarter':
        return endOfQuarter(refDate);

      default:
        return new Date();
    }
  }

  public add(toDate: Date, amount: number, unit: DateUnit): Date {
    switch (unit) {
      case 'Seconds':
        return addSeconds(toDate, amount);

      case 'Minutes':
        return addMinutes(toDate, amount);

      case 'Hours':
        return addHours(toDate, amount);

      case 'Days':
        return addDays(toDate, amount);

      case 'BusinessDays':
        return addBusinessDays(toDate, amount);

      case 'Weeks':
        return addWeeks(toDate, amount);

      case 'Months':
        return addMonths(toDate, amount);

      case 'Years':
        return addYears(toDate, amount);

      case 'Quarter':
        return addQuarters(toDate, amount);

      default:
        return null;
    }
  }

  public sub(fromDate: Date, amount: number, unit: DateUnit): Date {
    switch (unit) {
      case 'Seconds':
        return subSeconds(fromDate, amount);

      case 'Minutes':
        return subMinutes(fromDate, amount);

      case 'Hours':
        return subHours(fromDate, amount);

      case 'Days':
        return subDays(fromDate, amount);

      case 'BusinessDays':
        return subBusinessDays(fromDate, amount);

      case 'Weeks':
        return subWeeks(fromDate, amount);

      case 'Months':
        return subMonths(fromDate, amount);

      case 'Years':
        return subYears(fromDate, amount);

      case 'Quarter':
        return subQuarters(fromDate, amount);

      default:
        return null;
    }
  }

  public difference(a: Date, b: Date, type: 'Days' | 'Hours' | 'Minutes' | 'Seconds'): number {
    switch (type) {
      case 'Days':
        return differenceInDays(a, b);

      case 'Hours':
        return differenceInHours(a, b);

      case 'Minutes':
        return differenceInMinutes(a, b);

      case 'Seconds':
        return differenceInSeconds(a, b);

      default:
        return null;
    }
  }

  public isValid(date: Date): boolean {
    return isValid(date);
  }

  public isEqual(dateLeft: Date | number, dateRight: Date | number): boolean {
    return isEqual(dateLeft, dateRight);
  }

  public isAfter(testDate: Date | number, fromDate: Date | number): boolean {
    return isAfter(testDate, fromDate);
  }

  public isAfterOrEqual(testDate: Date | number, fromDate: Date | number): boolean {
    return isAfter(testDate, fromDate) || isEqual(testDate, fromDate);
  }

  public isBefore(testDate: Date | number, fromDate: Date | number): boolean {
    return isBefore(testDate, fromDate);
  }

  public isBeforeOrEqual(testDate: Date | number, fromDate: Date | number): boolean {
    return isBefore(testDate, fromDate) || isEqual(testDate, fromDate);
  }

  public isBetween(testDate: Date | number, left: Date | number, right: Date | number): boolean {
    return (
      (left ? this.isAfterOrEqual(testDate, left) : true) &&
      (right ? this.isBeforeOrEqual(testDate, right) : true)
    );
  }

  public differenceInCalendarDays(dateLeft: Date | number, dateRight: Date | number): number {
    return differenceInCalendarDays(dateLeft, dateRight);
  }

  public hasTime(checkDate: Date): boolean {
    return !!checkDate.getHours() || !!checkDate.getMinutes() || !!checkDate.getSeconds();
  }

  public hasTimeInUTC(checkDate: Date | string): boolean {
    if (typeof checkDate === 'string') {
      return this.parse(checkDate) && checkDate.endsWith('Z') && !checkDate.includes('T00:00:00');
    } else if (checkDate instanceof Date) {
      const utcDateStr = this.formatAsUTC(checkDate);
      return !utcDateStr.includes('T00:00:00');
    } else {
      return false;
    }
  }

  public setTime(dateWithTime: Date, targetDate: Date): void {
    if (dateWithTime && targetDate) {
      targetDate.setHours(dateWithTime.getHours());
      targetDate.setMinutes(dateWithTime.getMinutes());
      targetDate.setSeconds(dateWithTime.getSeconds());
      targetDate.setMilliseconds(dateWithTime.getMilliseconds());
    }
  }

  public convertDateRangePeriodToDateRange(period: DateRangePeriod): { start: Date; end: Date } {
    const today = this.now();

    switch (period) {
      case DateRangePeriod.Last7Days:
        return { start: this.sub(today, 7, 'Days'), end: today };

      case DateRangePeriod.Next7Days:
        return { start: today, end: this.add(today, 7, 'Days') };

      case DateRangePeriod.ThisMonth:
        return { start: this.startOf('Month'), end: this.resetTime(this.endOf('Month')) };

      case DateRangePeriod.ThreeMonths:
        return { start: this.sub(today, 3, 'Months'), end: today };

      case DateRangePeriod.YTD:
        return this.convertPeriodToDateRange(PeriodEnum.YTD);

      case DateRangePeriod.Year:
        return this.convertPeriodToDateRange(PeriodEnum.YEAR);

      case DateRangePeriod.ThreeYears:
        return this.convertPeriodToDateRange(PeriodEnum.THREE_YEARS);

      case DateRangePeriod.ThisQuarter:
        return { start: this.startOf('Quarter'), end: this.resetTime(this.endOf('Quarter')) };

      case DateRangePeriod.PastQuarter: {
        const currentQuarter = this.startOf('Quarter');
        const pastQuarter = this.sub(currentQuarter, 1, 'Quarter');

        return {
          start: this.startOf('Quarter', pastQuarter),
          end: this.resetTime(this.endOf('Quarter', pastQuarter)),
        };
      }

      case DateRangePeriod.AllTime:
        return { start: this.epoch(), end: today };
    }
  }

  public convertPeriodToDateRange(period: PeriodEnum): {
    start: Date;
    end: Date;
  } {
    const today = this.now();

    switch (period) {
      case 'DAY':
        return { start: this.sub(today, 1, 'Days'), end: today };

      case 'WEEK':
        return { start: this.sub(today, 1, 'Weeks'), end: today };

      case 'MONTHS':
        return { start: this.sub(today, 1, 'Months'), end: today };

      case 'THREE_MONTHS':
        return { start: this.sub(today, 3, 'Months'), end: today };

      case 'YEAR':
        return { start: this.sub(today, 1, 'Years'), end: today };

      case 'TWO_YEARS':
        return { start: this.sub(today, 2, 'Years'), end: today };

      case 'THREE_YEARS':
        return { start: this.sub(today, 3, 'Years'), end: today };

      case 'FIVE_YEARS':
        return { start: this.sub(today, 5, 'Years'), end: today };

      case 'YTD':
        return { start: this.startOf('Year', today), end: today };

      case 'ALL_TIME':
        return { start: this.epoch(), end: today };

      default:
        return null;
    }
  }

  public getQuarter(date: Date | string): string {
    if (!date) {
      return null;
    }

    const d = typeof date === 'string' ? this.parse(date) : date;
    const year = d.getFullYear();
    const q = Math.floor(d.getMonth() / 3) + 1;

    return `Q${q}'${year.toString().substring(2)}`;
  }

  public getYear(date: Date | string, format: 'full' | 'short' = 'full'): string {
    if (!date) {
      return null;
    }

    const d = typeof date === 'string' ? this.parse(date) : date;
    const year = d.getFullYear();

    return format === 'full' ? year.toString() : year.toString().substring(2);
  }

  public getMonthName(
    date: Date,
    format: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' = 'short',
  ): string {
    return date.toLocaleString(localStorage.getItem(LocalStorageConstants.LangCode), {
      month: format,
    });
  }
}
