import { Injectable } from '@angular/core';
import { InstrumentSearchDTO } from '@shared/dto/positions/instrument-search-dto';
import { TransactionDTO } from '@shared/dto/positions/transaction-dto';
import { GetNumberWithRound } from '@shared/pipes/number.pipe';

@Injectable({
  providedIn: 'root',
})
export class TransactionFieldsService {
  constructor() {}

  public getQty({
    type,
    qty,
    transactionSubType,
    productId,
  }: {
    type: TransactionDTO.TypeEnum;
    qty: number;
    transactionSubType?: TransactionDTO.SubTypeEnum;
    productId?: string;
  }): number {
    const absQty = Math.abs(qty);

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial:
      case TransactionDTO.TypeEnum.Buy:
      case TransactionDTO.TypeEnum.Income:
      case TransactionDTO.TypeEnum.Fee:
      case TransactionDTO.TypeEnum.Investment:
      case TransactionDTO.TypeEnum.InternalTransfer:
      case TransactionDTO.TypeEnum.Info:
      case TransactionDTO.TypeEnum.CorporateAction:
      case TransactionDTO.TypeEnum.Unknown:
      case TransactionDTO.TypeEnum.Revenue:
      case TransactionDTO.TypeEnum.ExpireOption:
      case TransactionDTO.TypeEnum.ExerciseOption:
        return absQty;

      case TransactionDTO.TypeEnum.Sell:
      case TransactionDTO.TypeEnum.Withdrawal:
      case TransactionDTO.TypeEnum.Expense:
      case TransactionDTO.TypeEnum.Tax:
        return -absQty;

      case TransactionDTO.TypeEnum.Repo:
        return transactionSubType === TransactionDTO.SubTypeEnum.Sell ? -absQty : absQty;

      case TransactionDTO.TypeEnum.Reversal:
        return transactionSubType === TransactionDTO.SubTypeEnum.Out ? -absQty : absQty;

      case TransactionDTO.TypeEnum.Adjustment:
      case TransactionDTO.TypeEnum.Fx:
      case TransactionDTO.TypeEnum.Interest:
      case TransactionDTO.TypeEnum.Margin:
        return transactionSubType === TransactionDTO.SubTypeEnum.Debit ? -absQty : absQty;

      case TransactionDTO.TypeEnum.SecurityTransfer:
      case TransactionDTO.TypeEnum.VirtualSecurityTransfer:
      case TransactionDTO.TypeEnum.Payment:
      case TransactionDTO.TypeEnum.Transfer:
        return transactionSubType === TransactionDTO.SubTypeEnum.Out ? -absQty : absQty;

      case TransactionDTO.TypeEnum.CashTransfer:
        return transactionSubType === TransactionDTO.SubTypeEnum.In ? absQty : -absQty;

      case TransactionDTO.TypeEnum.Block:
        return transactionSubType === TransactionDTO.SubTypeEnum.Blocking ? -absQty : absQty;

      case TransactionDTO.TypeEnum.ProductSubscription:
        return productId ? absQty : -absQty;

      case TransactionDTO.TypeEnum.ProductUnsubscription:
        return productId ? -absQty : absQty;

      default:
        return 0;
    }
  }

  public getValue({
    type,
    qty,
    price,
    instrument,
  }: {
    type: TransactionDTO.TypeEnum;
    qty: number;
    price?: number;
    instrument?: InstrumentSearchDTO;
  }): number {
    let value: number;
    const absQty = Math.abs(qty);

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial: {
        value = (absQty * price) / 100;
        break;
      }

      case TransactionDTO.TypeEnum.Sell:
      case TransactionDTO.TypeEnum.Buy:
      case TransactionDTO.TypeEnum.SecurityTransfer:
      case TransactionDTO.TypeEnum.Unknown:
      case TransactionDTO.TypeEnum.Reversal:
      case TransactionDTO.TypeEnum.Repo:
        switch (instrument.assetType) {
          case InstrumentSearchDTO.AssetTypeEnum.FixedIncome:
            value = (absQty * price) / 100;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Certificate:
            value = absQty * price * instrument.nominalCertificate;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Futures:
            value = absQty * price * instrument.futContSize;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Options:
            value = absQty * price * instrument.optContSize;
            break;
          default:
            value = absQty * price;
        }
        break;

      case TransactionDTO.TypeEnum.Adjustment:
      case TransactionDTO.TypeEnum.Fee:
      case TransactionDTO.TypeEnum.Fx:
      case TransactionDTO.TypeEnum.Margin:
      case TransactionDTO.TypeEnum.Interest:
      case TransactionDTO.TypeEnum.Tax:
      case TransactionDTO.TypeEnum.Investment:
      case TransactionDTO.TypeEnum.Withdrawal:
      case TransactionDTO.TypeEnum.VirtualSecurityTransfer:
      case TransactionDTO.TypeEnum.Block:
      case TransactionDTO.TypeEnum.InternalTransfer:
      case TransactionDTO.TypeEnum.Info:
      case TransactionDTO.TypeEnum.CorporateAction:
      case TransactionDTO.TypeEnum.Payment:
      case TransactionDTO.TypeEnum.ProductSubscription:
      case TransactionDTO.TypeEnum.ProductUnsubscription:
      case TransactionDTO.TypeEnum.Revenue:
      case TransactionDTO.TypeEnum.Expense:
      case TransactionDTO.TypeEnum.Transfer:
      case TransactionDTO.TypeEnum.CashTransfer:
      case TransactionDTO.TypeEnum.ExerciseOption:
      case TransactionDTO.TypeEnum.ExpireOption:
        value = absQty;
        break;

      case TransactionDTO.TypeEnum.Income:
        value =
          absQty *
          price *
          (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome ? 1 / 100 : 1);
        break;

      default:
        value = 0;
    }

    return GetNumberWithRound(value, 2);
  }

  public getGrossAmount({
    type,
    qty,
    price,
    transactionSubType,
    instrument,
    accrued = 0,
    productId,
  }: {
    type: TransactionDTO.TypeEnum;
    qty?: number;
    price?: number;
    transactionSubType?: TransactionDTO.SubTypeEnum;
    instrument?: InstrumentSearchDTO;
    accrued?: number;
    productId?: string;
  }): number {
    let grossAmount: number;
    const absQty = Math.abs(qty);

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial: {
        grossAmount = (absQty * price) / 100;
        break;
      }

      case TransactionDTO.TypeEnum.Buy:
      case TransactionDTO.TypeEnum.Sell:
      case TransactionDTO.TypeEnum.Unknown:
      case TransactionDTO.TypeEnum.Reversal:
      case TransactionDTO.TypeEnum.Repo: {
        let sign: -1 | 1;
        if (type === TransactionDTO.TypeEnum.Repo) {
          sign = transactionSubType === TransactionDTO.SubTypeEnum.Sell ? 1 : -1;
        } else if (type === TransactionDTO.TypeEnum.Reversal) {
          sign = transactionSubType === TransactionDTO.SubTypeEnum.Out ? 1 : -1;
        } else {
          sign = type === TransactionDTO.TypeEnum.Sell ? 1 : -1;
        }
        switch (instrument.assetType) {
          case InstrumentSearchDTO.AssetTypeEnum.FixedIncome:
            grossAmount = sign * ((absQty * price) / 100 + accrued);
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Certificate:
            grossAmount = sign * absQty * price * instrument.nominalCertificate;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Futures:
            grossAmount = sign * absQty * price * instrument.futContSize;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Options:
            grossAmount = sign * absQty * price * instrument.optContSize;
            break;
          default:
            grossAmount = sign * absQty * price;
        }
        break;
      }

      case TransactionDTO.TypeEnum.Fee:
        switch (transactionSubType) {
          case 'REIMBURSEMENT':
          case 'RETROCESSION':
          case 'INCENTIVE':
            grossAmount = absQty;
            break;

          default:
            grossAmount = -absQty;
            break;
        }
        break;

      case TransactionDTO.TypeEnum.Withdrawal:
      case TransactionDTO.TypeEnum.Expense:
        grossAmount = -absQty;
        break;

      case TransactionDTO.TypeEnum.Fx:
      case TransactionDTO.TypeEnum.Interest:
      case TransactionDTO.TypeEnum.Adjustment:
      case TransactionDTO.TypeEnum.Margin:
        grossAmount = transactionSubType === TransactionDTO.SubTypeEnum.Debit ? -absQty : absQty;
        break;

      case TransactionDTO.TypeEnum.Tax:
        grossAmount = -absQty;
        break;

      case TransactionDTO.TypeEnum.Income:
        grossAmount =
          absQty *
          price *
          (instrument.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome ? 1 / 100 : 1);
        break;

      case TransactionDTO.TypeEnum.Investment:
      case TransactionDTO.TypeEnum.Revenue:
        grossAmount = absQty;
        break;

      case TransactionDTO.TypeEnum.Payment:
        const sign = transactionSubType === TransactionDTO.SubTypeEnum.Out ? -1 : 1;
        grossAmount = sign * qty;
        break;

      case TransactionDTO.TypeEnum.ProductSubscription:
        grossAmount = productId ? absQty : -absQty;
        break;

      case TransactionDTO.TypeEnum.ProductUnsubscription:
        grossAmount = productId ? -absQty : absQty;
        break;

      case TransactionDTO.TypeEnum.Block:
      case TransactionDTO.TypeEnum.CashTransfer:
      case TransactionDTO.TypeEnum.CorporateAction:
      case TransactionDTO.TypeEnum.SecurityTransfer:
      case TransactionDTO.TypeEnum.Transfer:
      case TransactionDTO.TypeEnum.VirtualSecurityTransfer:
      default:
        grossAmount = 0;
    }

    return GetNumberWithRound(grossAmount, 2);
  }

  public getNetAmount({
    type,
    qty,
    commission = 0,
    price,
    transactionSubType,
    instrument,
    accrued = 0,
    productId,
  }: {
    type: TransactionDTO.TypeEnum;
    qty?: number;
    commission?: number;
    price?: number;
    transactionSubType?: TransactionDTO.SubTypeEnum;
    instrument?: InstrumentSearchDTO;
    accrued?: number;
    productId?: string;
  }): number {
    let netAmount: number;
    const absQty = Math.abs(qty);

    switch (type) {
      case TransactionDTO.TypeEnum.BondRedemption:
      case TransactionDTO.TypeEnum.BondRedemptionPartial:
        netAmount = (absQty * price) / 100 - commission;
        break;

      case TransactionDTO.TypeEnum.Buy:
      case TransactionDTO.TypeEnum.Sell:
      case TransactionDTO.TypeEnum.Unknown:
      case TransactionDTO.TypeEnum.Reversal:
      case TransactionDTO.TypeEnum.Repo: {
        if (!instrument) {
          netAmount = null;
          break;
        }

        let sign: -1 | 1;
        if (type === TransactionDTO.TypeEnum.Repo) {
          sign = transactionSubType === TransactionDTO.SubTypeEnum.Sell ? 1 : -1;
        } else if (type === TransactionDTO.TypeEnum.Reversal) {
          sign = transactionSubType === TransactionDTO.SubTypeEnum.Out ? 1 : -1;
        } else {
          sign = type === TransactionDTO.TypeEnum.Sell ? 1 : -1;
        }
        switch (instrument.assetType) {
          case InstrumentSearchDTO.AssetTypeEnum.FixedIncome:
            netAmount = sign * ((absQty * price) / 100 + accrued) - commission;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Certificate:
            netAmount = sign * absQty * price * instrument.nominalCertificate - commission;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Futures:
            netAmount = sign * absQty * price * instrument.futContSize - commission;
            break;
          case InstrumentSearchDTO.AssetTypeEnum.Options:
            netAmount = sign * absQty * price * instrument.optContSize - commission;
            break;
          default:
            netAmount = sign * absQty * price - commission;
        }
        break;
      }

      case TransactionDTO.TypeEnum.Fee:
        switch (transactionSubType) {
          case 'REIMBURSEMENT':
          case 'RETROCESSION':
          case 'INCENTIVE':
            netAmount = absQty - commission;
            break;

          default:
            netAmount = -absQty - commission;
            break;
        }
        break;

      case TransactionDTO.TypeEnum.Withdrawal:
      case TransactionDTO.TypeEnum.Expense:
        netAmount = -absQty - commission;
        break;

      case TransactionDTO.TypeEnum.Adjustment:
      case TransactionDTO.TypeEnum.Interest:
      case TransactionDTO.TypeEnum.Fx:
      case TransactionDTO.TypeEnum.Margin:
        netAmount =
          (transactionSubType === TransactionDTO.SubTypeEnum.Debit ? -absQty : absQty) - commission;
        break;

      case TransactionDTO.TypeEnum.Income:
        netAmount =
          absQty *
            price *
            (instrument?.assetType === InstrumentSearchDTO.AssetTypeEnum.FixedIncome
              ? 1 / 100
              : 1) -
          commission;
        break;

      case TransactionDTO.TypeEnum.Tax:
        return -absQty - commission;

      case TransactionDTO.TypeEnum.Investment:
      case TransactionDTO.TypeEnum.Revenue:
        netAmount = absQty - commission;
        break;

      case TransactionDTO.TypeEnum.SecurityTransfer:
        netAmount = -commission;
        break;

      case TransactionDTO.TypeEnum.Block:
        if (instrument?.assetType === InstrumentSearchDTO.AssetTypeEnum.Cash) {
          netAmount = transactionSubType === TransactionDTO.SubTypeEnum.Blocking ? -absQty : absQty;
        } else {
          netAmount = 0;
        }
        break;

      case TransactionDTO.TypeEnum.InternalTransfer:
      case TransactionDTO.TypeEnum.Info:
      case TransactionDTO.TypeEnum.CorporateAction:
      case TransactionDTO.TypeEnum.Transfer:
      case TransactionDTO.TypeEnum.CashTransfer:
        netAmount = commission ? -commission : 0;
        break;

      case TransactionDTO.TypeEnum.Payment:
        const sign = transactionSubType === TransactionDTO.SubTypeEnum.Out ? -1 : 1;
        netAmount = sign * qty - commission;
        break;

      case TransactionDTO.TypeEnum.ProductSubscription:
        netAmount = productId ? absQty : -absQty;
        break;

      case TransactionDTO.TypeEnum.ProductUnsubscription:
        netAmount = productId ? -absQty : absQty;
        break;

      case TransactionDTO.TypeEnum.ExerciseOption:
      case TransactionDTO.TypeEnum.ExpireOption:
      case TransactionDTO.TypeEnum.VirtualSecurityTransfer:
      default:
        netAmount = 0;
    }

    return GetNumberWithRound(netAmount, 2);
  }
}
