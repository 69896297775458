/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentTinyDTO } from './attachment-tiny-dto';

export interface JiraCreateIssueDTO {
  attachments?: Array<AttachmentTinyDTO>;
  description?: string;
  initiatorEmail?: string;
  issueType?: JiraCreateIssueDTO.IssueTypeEnum;
  metaData?: string;
  page?: string;
  priority?: JiraCreateIssueDTO.PriorityEnum;
  summary?: string;
  system?: JiraCreateIssueDTO.SystemEnum;
}
export namespace JiraCreateIssueDTO {
  export type IssueTypeEnum = 'BUG' | 'ENHANCEMENT' | 'NEW_REQUEST' | 'REQUEST' | 'SUPPORT';
  export const IssueTypeEnum = {
    Bug: 'BUG' as IssueTypeEnum,
    Enhancement: 'ENHANCEMENT' as IssueTypeEnum,
    NewRequest: 'NEW_REQUEST' as IssueTypeEnum,
    Request: 'REQUEST' as IssueTypeEnum,
    Support: 'SUPPORT' as IssueTypeEnum,
  };
  export type PriorityEnum = 'BLOCKER' | 'HIGH' | 'LOW' | 'MEDIUM' | 'MINOR';
  export const PriorityEnum = {
    Blocker: 'BLOCKER' as PriorityEnum,
    High: 'HIGH' as PriorityEnum,
    Low: 'LOW' as PriorityEnum,
    Medium: 'MEDIUM' as PriorityEnum,
    Minor: 'MINOR' as PriorityEnum,
  };
  export type SystemEnum = 'IOS_CLIENT_APPLICATION' | 'IOS_MANAGER_APPLICATION' | 'WEB_VERSION';
  export const SystemEnum = {
    IosClientApplication: 'IOS_CLIENT_APPLICATION' as SystemEnum,
    IosManagerApplication: 'IOS_MANAGER_APPLICATION' as SystemEnum,
    WebVersion: 'WEB_VERSION' as SystemEnum,
  };
}
