import { Pipe, PipeTransform } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { TwelveDataService } from '@shared/api/twelve-data.service';

@Pipe({
  name: 'getInstrumentImgSrc',
})
export class GetInstrumentImgSrcPipe implements PipeTransform {
  constructor(private twelveDataService: TwelveDataService) {}

  public transform(bloombergId: string, micCode: string): Observable<string> {
    if (!bloombergId) {
      return null;
    }

    const symbol = bloombergId.trim().split(' ').shift();

    if (!symbol) {
      return null;
    }

    return this.twelveDataService
      .getInstrumentLogo(symbol, micCode)
      .pipe(catchError(() => of(null)));
  }
}
