import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Authorities } from '@shared/constants/authorities';
import { LocalStorageConstants } from '@shared/constants/local-storage-constants';
import { SeoRouteData } from '@shared/helpers/seo.service';
import { CanShowFullScreenCanActivateChildGuard } from 'app/guards/can-show-full-screen.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import {
  AuthCanActivateChildGuard,
  AuthCanActivateGuard,
  AuthCanMatchGuard,
} from './guards/auth.guard';
import { AuthoritiesCanActivateGuard } from './guards/authorities.guard';
import {
  CleanLocalStorageCanDeactivateGuard,
  CleanLocalStorageRouteData,
} from './guards/clean-local-storage-items.guard';
import { DevSiteCanActivateGuard } from './guards/dev-site.guard';
import { LocaleCanActivateChildGuard } from './guards/locale.guard';
import { QueryParamsSaverCanActivateChildGuard } from './guards/query-params-saver.guard';
import { SeoCanActivateChildGuard } from './guards/seo.guard';
import { SubscriptionCanMatchGuard } from './guards/subscription.guard';
import { LayoutRouteData, LayoutState } from './layout/layout.state';
import { LocalStoreResolver } from './resolvers/local-store.resolver';
import { LogoStateResolver } from './resolvers/logo-state.resolver';
import { UserActivityResolver } from './resolvers/user-activity.resolver';
import { UserStateResolver } from './resolvers/user-state.resolver';

const routes: Routes = [
  {
    path: 'login',
    canActivateChild: [
      SeoCanActivateChildGuard,
      LocaleCanActivateChildGuard,
      CanShowFullScreenCanActivateChildGuard,
    ],
    resolve: {
      logoState: LogoStateResolver,
    },
    loadChildren: async () => (await import('./pages/login/login.module')).LoginModule,
  },
  {
    path: 'privacy',
    canActivateChild: [SeoCanActivateChildGuard, LocaleCanActivateChildGuard],
    loadChildren: async () => (await import('./pages/privacy/privacy.module')).PrivacyModule,
  },
  {
    path: 'confirm-email',
    canActivateChild: [SeoCanActivateChildGuard, LocaleCanActivateChildGuard],
    loadChildren: async () =>
      (await import('./pages/confirm-email/confirm-email.module')).ConfirmEmailModule,
  },
  {
    path: 'pdf',
    canActivateChild: [SeoCanActivateChildGuard],
    resolve: {
      logoState: LogoStateResolver,
      userState: UserStateResolver,
    },
    loadChildren: async () => (await import('@pages/pdf/pdf.module')).PdfModule,
  },
  {
    path: 'terms-of-use',
    canActivateChild: [SeoCanActivateChildGuard, LocaleCanActivateChildGuard],
    loadChildren: async () =>
      (await import('./pages/terms-of-use/terms-of-use.module')).TermsOfUseModule,
  },
  {
    path: 'cookie-policy',
    canActivateChild: [SeoCanActivateChildGuard, LocaleCanActivateChildGuard],
    loadChildren: async () =>
      (await import('./pages/cookie-policy/cookie-policy.module')).CookiePolicyModule,
  },
  {
    path: 'test',
    component: LayoutComponent,
    canActivate: [DevSiteCanActivateGuard, LocaleCanActivateChildGuard],
    resolve: {
      logoState: LogoStateResolver,
    },
    loadChildren: async () => (await import('./pages/test-page/test-page.module')).TestPageModule,
  },
  {
    path: 'system-settings',
    component: LayoutComponent,
    canActivate: [AuthoritiesCanActivateGuard],
    canActivateChild: [
      AuthCanActivateChildGuard,
      SeoCanActivateChildGuard,
      CanShowFullScreenCanActivateChildGuard,
    ],
    canMatch: [AuthCanMatchGuard],
    resolve: {
      userActivity: UserActivityResolver,
      userState: UserStateResolver,
      localStore: LocalStoreResolver,
    },
    data: {
      authorities: [Authorities.SettingsView],
      layout: {
        canShowFullScreen: false,
      } as LayoutRouteData,
    },
    loadChildren: async () =>
      (await import('./pages/system-settings/system-settings.module')).SystemSettingsModule,
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthCanActivateGuard],
    canActivateChild: [
      AuthCanActivateChildGuard,
      SeoCanActivateChildGuard,
      LocaleCanActivateChildGuard,
      CanShowFullScreenCanActivateChildGuard,
      QueryParamsSaverCanActivateChildGuard,
    ],
    resolve: {
      userActivity: UserActivityResolver,
      logoState: LogoStateResolver,
      userState: UserStateResolver,
      localStore: LocalStoreResolver,
    },
    children: [
      {
        path: 'dashboard',
        canMatch: [AuthCanMatchGuard],
        data: {
          layout: {
            canShowFullScreen: false,
          } as LayoutRouteData,
        },
        loadChildren: async () =>
          (await import('./pages/dashboard/dashboard.module')).DashboardModule,
      },
      {
        path: 'positions',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () =>
          (await import('./pages/positions/positions.module')).PositionsModule,
      },
      {
        path: 'products',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () => (await import('./pages/products/products.module')).ProductsModule,
      },
      {
        path: 'transactions',
        canMatch: [AuthCanMatchGuard],
        canDeactivate: [CleanLocalStorageCanDeactivateGuard],
        data: {
          cleanLocalStorage: {
            items: [LocalStorageConstants.ConnectorsCurrentDateRange],
          } as CleanLocalStorageRouteData,
        },
        loadChildren: async () =>
          (await import('./pages/transactions/transactions.module')).TransactionsModule,
      },
      {
        path: 'instruments',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () =>
          (await import('./pages/instruments/instruments.module')).InstrumentsModule,
      },
      {
        path: 'screener',
        canMatch: [AuthCanMatchGuard, SubscriptionCanMatchGuard],
        data: {
          seo: {
            pageTitle: 'pages.screener.title',
          } as SeoRouteData,
          authorities: [Authorities.ModelPortfoliosView],
          bySubscription: true,
        },
        loadChildren: async () => (await import('./pages/screener/screener.module')).ScreenerModule,
      },
      {
        path: 'risks',
        canMatch: [AuthCanMatchGuard, SubscriptionCanMatchGuard],
        data: {
          authorities: [
            Authorities.MandateRiskProfileView,
            Authorities.RestrictionAlertView,
            Authorities.RiskReportView,
          ],
        },
        loadChildren: async () => (await import('./pages/risks/risks.module')).RisksModule,
      },
      {
        path: 'accounts',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () => (await import('./pages/accounts/accounts.module')).AccountsModule,
      },
      {
        path: 'portfolios',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () =>
          (await import('./pages/portfolios/portfolios.module')).PortfoliosModule,
      },
      {
        path: 'reports',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () => (await import('./pages/reports/reports.module')).ReportsModule,
      },
      {
        path: 'detail',
        canMatch: [AuthCanMatchGuard],
        data: {
          layout: {
            canShowFullScreen: true,
          } as LayoutRouteData,
        },
        loadChildren: async () => (await import('./pages/detail/detail.module')).DetailModule,
      },
      {
        path: 'marketplace',
        canMatch: [AuthCanMatchGuard, SubscriptionCanMatchGuard],
        data: {
          seo: {
            pageTitle: 'marketplace.title',
          } as SeoRouteData,
          breadcrumb: '',
          authorities: [Authorities.MarketplaceView],
          bySubscription: true,
        },
        loadChildren: async () =>
          (await import('./marketplace/marketplace.module')).MarketplaceModule,
      },
      {
        path: 'import',
        canMatch: [AuthCanMatchGuard],
        loadChildren: async () => (await import('./pages/import/import.module')).ImportModule,
      },
      {
        path: '**',
        canDeactivate: [
          () => {
            inject(LayoutState).subscriptionNotificationData$.next(null);
            return true;
          },
        ],
        loadComponent: () =>
          import('./pages/not-found/not-found.component').then((c) => c.NotFoundComponent),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
})
export class AppRoutingModule {}
