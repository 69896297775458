<div class="app-dialog-notification">
  <div class="app-dialog-notification__header">
    <app-svg class="app-dialog-notification__icon"
             [icon]="'StatusAttentionRectangle_24'"
             [disabledHover]="true">
    </app-svg>

    <span class="heading-4-special">
      {{ 'dialogs.expirationDialog.title'|translate }}
    </span>
  </div>

  <div class="app-dialog-notification__body">
    <span class="subtitle-2">
      {{ 'dialogs.expirationDialog.message'|translate }}
      {{ ' ' + (secondsLeft$|async) + ' sec.'}}
      {{ 'dialogs.expirationDialog.message2'|translate }}
    </span>

    <mat-progress-bar mode="determinate"
                      [value]="progressLeft$|async"
                      style="margin-top: 20px;">
    </mat-progress-bar>
  </div>

  <div class="app-dialog-notification__footer">
    <app-button class="mrgn-rgt-10"
                (click)="_onContinueClick()">
      {{ 'dialogs.expirationDialog.continue'|translate }}
    </app-button>
  </div>
</div>