/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface CustodianDTO {
  bank?: CustodianDTO.BankEnum;
  bicCode?: string;
  code: string;
  counterpartyTrades?: boolean;
  country: string;
  cysecReportable?: boolean;
  dma?: boolean;
  email?: string;
  entityType?: CustodianDTO.EntityTypeEnum;
  fullName: string;
  id?: string;
  lei?: string;
  name: string;
  offCustodian?: boolean;
  otc?: boolean;
  phone?: string;
  regulatorName?: string;
  status: CustodianDTO.StatusEnum;
}
export namespace CustodianDTO {
  export type BankEnum =
    | 'ADCB'
    | 'ALFA'
    | 'ATON'
    | 'BCS'
    | 'BKS'
    | 'BTA'
    | 'CBH'
    | 'CBH_BS'
    | 'CITI'
    | 'DASL'
    | 'DBS'
    | 'DMBL'
    | 'DOLFIN'
    | 'DOM_RF'
    | 'EBICS'
    | 'EDR'
    | 'ENBD'
    | 'EUROBANK_CYPRUS_LTD'
    | 'EXANTE'
    | 'FAB'
    | 'FREEDOM_FINANCE'
    | 'GENERAL_INVEST'
    | 'GPP'
    | 'HELLENIC'
    | 'IBKR'
    | 'IFX'
    | 'INTESA'
    | 'INVESTEC'
    | 'ITI'
    | 'JPM'
    | 'JULIUS_BAER'
    | 'KRISTAL'
    | 'LINEAR'
    | 'LOKO_BANK'
    | 'MARKET_SECURITIES'
    | 'MEGA_EQUITY'
    | 'META_X'
    | 'MISR'
    | 'NAT_WEST'
    | 'NLB'
    | 'OTP'
    | 'QSF_PHYSICAL_SAFE'
    | 'QUEEN_STREET_FINANCE'
    | 'RAIFFEISEN_BANK'
    | 'RAISON_ASSET_MANAGEMENT'
    | 'RJO'
    | 'SALTEDGE'
    | 'SOVA'
    | 'TAVIRA'
    | 'TINKOFF'
    | 'TSIFRA'
    | 'UBS'
    | 'UBS_special'
    | 'UNKNOWN'
    | 'UNLIMIT'
    | 'VANTAGE'
    | 'VP'
    | 'VTB'
    | 'WH_IRELAND'
    | 'WIO'
    | 'XALQ';
  export const BankEnum = {
    Adcb: 'ADCB' as BankEnum,
    Alfa: 'ALFA' as BankEnum,
    Aton: 'ATON' as BankEnum,
    Bcs: 'BCS' as BankEnum,
    Bks: 'BKS' as BankEnum,
    Bta: 'BTA' as BankEnum,
    Cbh: 'CBH' as BankEnum,
    CbhBs: 'CBH_BS' as BankEnum,
    Citi: 'CITI' as BankEnum,
    Dasl: 'DASL' as BankEnum,
    Dbs: 'DBS' as BankEnum,
    Dmbl: 'DMBL' as BankEnum,
    Dolfin: 'DOLFIN' as BankEnum,
    DomRf: 'DOM_RF' as BankEnum,
    Ebics: 'EBICS' as BankEnum,
    Edr: 'EDR' as BankEnum,
    Enbd: 'ENBD' as BankEnum,
    EurobankCyprusLtd: 'EUROBANK_CYPRUS_LTD' as BankEnum,
    Exante: 'EXANTE' as BankEnum,
    Fab: 'FAB' as BankEnum,
    FreedomFinance: 'FREEDOM_FINANCE' as BankEnum,
    GeneralInvest: 'GENERAL_INVEST' as BankEnum,
    Gpp: 'GPP' as BankEnum,
    Hellenic: 'HELLENIC' as BankEnum,
    Ibkr: 'IBKR' as BankEnum,
    Ifx: 'IFX' as BankEnum,
    Intesa: 'INTESA' as BankEnum,
    Investec: 'INVESTEC' as BankEnum,
    Iti: 'ITI' as BankEnum,
    Jpm: 'JPM' as BankEnum,
    JuliusBaer: 'JULIUS_BAER' as BankEnum,
    Kristal: 'KRISTAL' as BankEnum,
    Linear: 'LINEAR' as BankEnum,
    LokoBank: 'LOKO_BANK' as BankEnum,
    MarketSecurities: 'MARKET_SECURITIES' as BankEnum,
    MegaEquity: 'MEGA_EQUITY' as BankEnum,
    MetaX: 'META_X' as BankEnum,
    Misr: 'MISR' as BankEnum,
    NatWest: 'NAT_WEST' as BankEnum,
    Nlb: 'NLB' as BankEnum,
    Otp: 'OTP' as BankEnum,
    QsfPhysicalSafe: 'QSF_PHYSICAL_SAFE' as BankEnum,
    QueenStreetFinance: 'QUEEN_STREET_FINANCE' as BankEnum,
    RaiffeisenBank: 'RAIFFEISEN_BANK' as BankEnum,
    RaisonAssetManagement: 'RAISON_ASSET_MANAGEMENT' as BankEnum,
    Rjo: 'RJO' as BankEnum,
    Saltedge: 'SALTEDGE' as BankEnum,
    Sova: 'SOVA' as BankEnum,
    Tavira: 'TAVIRA' as BankEnum,
    Tinkoff: 'TINKOFF' as BankEnum,
    Tsifra: 'TSIFRA' as BankEnum,
    Ubs: 'UBS' as BankEnum,
    UbsSpecial: 'UBS_special' as BankEnum,
    Unknown: 'UNKNOWN' as BankEnum,
    Unlimit: 'UNLIMIT' as BankEnum,
    Vantage: 'VANTAGE' as BankEnum,
    Vp: 'VP' as BankEnum,
    Vtb: 'VTB' as BankEnum,
    WhIreland: 'WH_IRELAND' as BankEnum,
    Wio: 'WIO' as BankEnum,
    Xalq: 'XALQ' as BankEnum,
  };
  export type EntityTypeEnum =
    | 'BANK_IN_THIRD_COUNTRY'
    | 'CENTRAL_BANK'
    | 'CLEARING_HOUSE'
    | 'EEA_CREDIT_INSTITUTION'
    | 'ELECTRONIC_MONEY_INSTITUTION'
    | 'INVESTMENT_FIRM'
    | 'N_A'
    | 'OTHER'
    | 'PAYMENT_INSTITUTION'
    | 'QUALIFYING_MARKET_FUND'
    | 'RECOGNIZED_EXCHANGE';
  export const EntityTypeEnum = {
    BankInThirdCountry: 'BANK_IN_THIRD_COUNTRY' as EntityTypeEnum,
    CentralBank: 'CENTRAL_BANK' as EntityTypeEnum,
    ClearingHouse: 'CLEARING_HOUSE' as EntityTypeEnum,
    EeaCreditInstitution: 'EEA_CREDIT_INSTITUTION' as EntityTypeEnum,
    ElectronicMoneyInstitution: 'ELECTRONIC_MONEY_INSTITUTION' as EntityTypeEnum,
    InvestmentFirm: 'INVESTMENT_FIRM' as EntityTypeEnum,
    NA: 'N_A' as EntityTypeEnum,
    Other: 'OTHER' as EntityTypeEnum,
    PaymentInstitution: 'PAYMENT_INSTITUTION' as EntityTypeEnum,
    QualifyingMarketFund: 'QUALIFYING_MARKET_FUND' as EntityTypeEnum,
    RecognizedExchange: 'RECOGNIZED_EXCHANGE' as EntityTypeEnum,
  };
  export type StatusEnum = 'Active' | 'Deactivated';
  export const StatusEnum = {
    Active: 'Active' as StatusEnum,
    Deactivated: 'Deactivated' as StatusEnum,
  };
}
