<div #label
     class="app-clipped-text-tooltip__label"
     [class.--line-limit]="lineLimit"
     [style.--line-limit]="lineLimit">
  <ng-content></ng-content>
</div>

<div #labelTest
     class="app-clipped-text-tooltip__test">
</div>

<ng-template #tooltipTemplate>
  <div class="app-clipped-text-tooltip__tooltip"
       [class.--visible]="tooltipVisible$|async"
       [style.top.px]="tooltipTop$|async"
       [style.left.px]="hostRect.left"
       @tooltipAnimation>
    {{ message }}
  </div>
</ng-template>