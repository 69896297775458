export enum EntityAliases {
  Test = 'test',
  Account = 'account',
  AssetStructure = 'asset-structure',
  BankAccount = 'bank-account',
  Client = 'client',
  Lead = 'lead',
  Position = 'position',
  Product = 'product',
  Instrument = 'instrument',
  Transaction = 'transaction',
  Manager = 'manager',
  Company = 'company',
  Custodian = 'custodian',
  Fee = 'fee',
  SovaExecutionReport = 'sova-execution-report',
  Mandate = 'mandate',
  Portfolio = 'portfolio',
  Order = 'order',
  CounterParty = 'counter-party',
  CouponPaymentAll = 'coupon-payment-all',
  CouponPaymentMonth = 'coupon-payment-month',
  Strategy = 'strategy',
  Recommendation = 'recommendation',
  ChangeRequest = 'change-request',
  RiskProfile = 'risk-profile',
  Process = 'process',
  Notification = 'notification',
  ValueDateRules = 'value-date-rules',
  PrivateInstrumentPosition = 'private-instrument-position',
  PrivateInstrument = 'private-instrument',
  GlobalAllocation = 'global-allocation',
  GlobalAllocationTemplate = 'global-allocation-template',
}
