import { SvgComponent } from '@ui/svg/svg.component';

export type IconNames = keyof typeof Icons;

export enum Icons {
  ArrowShortLeft_24 = '/assets/img/icons/arrow-short-left_24.svg',
  ArrowShortLeft_16 = '/assets/img/icons/arrow-short-left_16.svg',
  ArrowShortLeft_12 = '/assets/img/icons/arrow-short-left_12.svg',
  ArrowShortRight_24 = '/assets/img/icons/arrow-short-right_24.svg',
  ArrowShortRight_16 = '/assets/img/icons/arrow-short-right_16.svg',
  ArrowShortRight_12 = '/assets/img/icons/arrow-short-right_12.svg',
  ArrowShortTop_24 = '/assets/img/icons/arrow-short-top_24.svg',
  ArrowShortTop_16 = '/assets/img/icons/arrow-short-top_16.svg',
  ArrowShortTop_12 = '/assets/img/icons/arrow-short-top_12.svg',
  ArrowShortDown_24 = '/assets/img/icons/arrow-short-down_24.svg',
  ArrowShortDown_16 = '/assets/img/icons/arrow-short-down_16.svg',
  ArrowShortDown_12 = '/assets/img/icons/arrow-short-down_12.svg',
  ArrowMediumLeft_24 = '/assets/img/icons/arrow-medium-left_24.svg',
  ArrowMediumLeft_16 = '/assets/img/icons/arrow-medium-left_16.svg',
  ArrowMediumLeft_12 = '/assets/img/icons/arrow-medium-left_12.svg',
  ArrowMediumRight_24 = '/assets/img/icons/arrow-medium-right_24.svg',
  ArrowMediumRight_16 = '/assets/img/icons/arrow-medium-right_16.svg',
  ArrowMediumRight_12 = '/assets/img/icons/arrow-medium-right_12.svg',
  ArrowMediumTop_24 = '/assets/img/icons/arrow-medium-top_24.svg',
  ArrowMediumTop_16 = '/assets/img/icons/arrow-medium-top_16.svg',
  ArrowMediumTop_12 = '/assets/img/icons/arrow-medium-top_12.svg',
  ArrowMediumDown_24 = '/assets/img/icons/arrow-medium-down_24.svg',
  ArrowMediumDown_16 = '/assets/img/icons/arrow-medium-down_16.svg',
  ArrowMediumDown_12 = '/assets/img/icons/arrow-medium-down_12.svg',
  ArrowLongLeft_24 = '/assets/img/icons/arrow-long-left_24.svg',
  ArrowLongLeft_16 = '/assets/img/icons/arrow-long-left_16.svg',
  ArrowLongLeft_12 = '/assets/img/icons/arrow-long-left_12.svg',
  ArrowLongRight_24 = '/assets/img/icons/arrow-long-right_24.svg',
  ArrowLongRight_16 = '/assets/img/icons/arrow-long-right_16.svg',
  ArrowLongRight_12 = '/assets/img/icons/arrow-long-right_12.svg',
  ArrowLongTop_24 = '/assets/img/icons/arrow-long-top_24.svg',
  ArrowLongTop_16 = '/assets/img/icons/arrow-long-top_16.svg',
  ArrowLongTop_12 = '/assets/img/icons/arrow-long-top_12.svg',
  ArrowLongDown_24 = '/assets/img/icons/arrow-long-down_24.svg',
  ArrowLongDown_16 = '/assets/img/icons/arrow-long-down_16.svg',
  ArrowLongDown_12 = '/assets/img/icons/arrow-long-down_12.svg',
  Forward_24 = '/assets/img/icons/forward_24.svg',
  Forward_16 = '/assets/img/icons/forward_16.svg',
  Forward_12 = '/assets/img/icons/forward_12.svg',
  ArrowRightSharpCircle24 = '/assets/img/icons/arrow-right-sharp-circle_24.svg',
  ArrowRightSharpCircle20 = '/assets/img/icons/arrow-right-sharp-circle_20.svg',
  ArrowRightSharpCircle16 = '/assets/img/icons/arrow-right-sharp-circle_16.svg',
  ArrowRightSharpCircle12 = '/assets/img/icons/arrow-right-sharp-circle_12.svg',
  ArrowRightSharpCircleCrossed24 = '/assets/img/icons/arrow-right-sharp-circle-crossed_24.svg',
  ArrowRightSharpCircleCrossed20 = '/assets/img/icons/arrow-right-sharp-circle-crossed_20.svg',
  ArrowRightSharpCircleCrossed16 = '/assets/img/icons/arrow-right-sharp-circle-crossed_16.svg',
  ArrowRightSharpCircleCrossed12 = '/assets/img/icons/arrow-right-sharp-circle-crossed_12.svg',
  Expand_24 = '/assets/img/icons/expand_24.svg',
  Expand_16 = '/assets/img/icons/expand_16.svg',
  Expand_12 = '/assets/img/icons/expand_12.svg',
  Rollup_24 = '/assets/img/icons/rollup_24.svg',
  Rollup_16 = '/assets/img/icons/rollup_16.svg',
  Rollup_12 = '/assets/img/icons/rollup_12.svg',
  Back_24 = '/assets/img/icons/back_24.svg',
  Back_16 = '/assets/img/icons/back_16.svg',
  Back_12 = '/assets/img/icons/back_12.svg',
  Move_24 = '/assets/img/icons/move_24.svg',
  Move_16 = '/assets/img/icons/move_16.svg',
  Move_12 = '/assets/img/icons/move_12.svg',
  Done_24 = '/assets/img/icons/done_24.svg',
  Done_16 = '/assets/img/icons/done_16.svg',
  Done_12 = '/assets/img/icons/done_12.svg',
  Close_24 = '/assets/img/icons/close_24.svg',
  Close_16 = '/assets/img/icons/close_16.svg',
  Close_12 = '/assets/img/icons/close_12.svg',
  Placeholder_24 = '/assets/img/icons/placeholder_24.svg',
  Placeholder_16 = '/assets/img/icons/placeholder_16.svg',
  Placeholder_12 = '/assets/img/icons/placeholder_12.svg',
  Add_24 = '/assets/img/icons/add_24.svg',
  Add_16 = '/assets/img/icons/add_16.svg',
  Add_12 = '/assets/img/icons/add_12.svg',
  Skip_24 = '/assets/img/icons/skip_24.svg',
  Skip_16 = '/assets/img/icons/skip_16.svg',
  Skip_12 = '/assets/img/icons/skip_12.svg',
  Search_24 = '/assets/img/icons/search_24.svg',
  Search_16 = '/assets/img/icons/search_16.svg',
  Search_12 = '/assets/img/icons/search_12.svg',
  MenuHorizontal_24 = '/assets/img/icons/menu-horizontal_24.svg',
  MenuHorizontal_16 = '/assets/img/icons/menu-horizontal_16.svg',
  MenuHorizontal_12 = '/assets/img/icons/menu-horizontal_12.svg',
  MenuVertical_24 = '/assets/img/icons/menu-vertical_24.svg',
  MenuVertical_16 = '/assets/img/icons/menu-vertical_16.svg',
  MenuVertical_12 = '/assets/img/icons/menu-vertical_12.svg',
  Edit_24 = '/assets/img/icons/edit_24.svg',
  Edit_16 = '/assets/img/icons/edit_16.svg',
  Edit_12 = '/assets/img/icons/edit_12.svg',
  OpenEye_24 = '/assets/img/icons/open-eye_24.svg',
  OpenEye_16 = '/assets/img/icons/open-eye_16.svg',
  OpenEye_12 = '/assets/img/icons/open-eye_12.svg',
  ClosedEye_24 = '/assets/img/icons/closed-eye_24.svg',
  ClosedEye_16 = '/assets/img/icons/closed-eye_16.svg',
  ClosedEye_12 = '/assets/img/icons/closed-eye_12.svg',
  ShareSquare_24 = '/assets/img/icons/share-square_24.svg',
  ShareSquare_16 = '/assets/img/icons/share-square_16.svg',
  ShareSquare_12 = '/assets/img/icons/share-square_12.svg',
  Share_24 = '/assets/img/icons/share_24.svg',
  Share_16 = '/assets/img/icons/share_16.svg',
  Share_12 = '/assets/img/icons/share_12.svg',
  ShareOutline_24 = '/assets/img/icons/share-outline_24.svg',
  ShareOutline_16 = '/assets/img/icons/share-outline_16.svg',
  ShareOutline_12 = '/assets/img/icons/share-outline_12.svg',
  Link_24 = '/assets/img/icons/link_24.svg',
  Link_16 = '/assets/img/icons/link_16.svg',
  Link_12 = '/assets/img/icons/link_12.svg',
  ShareLink_24 = '/assets/img/icons/share-link_24.svg',
  ShareLink_16 = '/assets/img/icons/share-link_16.svg',
  ShareLink_12 = '/assets/img/icons/share-link_12.svg',
  OpenInNewWindow_24 = '/assets/img/icons/open-in-new-window_24.svg',
  OpenInNewWindow_16 = '/assets/img/icons/open-in-new-window_16.svg',
  OpenInNewWindow_12 = '/assets/img/icons/open-in-new-window_12.svg',
  Hide_24 = '/assets/img/icons/hide_24.svg',
  Hide_16 = '/assets/img/icons/hide_16.svg',
  Hide_12 = '/assets/img/icons/hide_12.svg',
  Transaction_24 = '/assets/img/icons/transaction_24.svg',
  Transaction_16 = '/assets/img/icons/transaction_16.svg',
  Transaction_12 = '/assets/img/icons/transaction_12.svg',
  Calendar_24 = '/assets/img/icons/calendar_24.svg',
  Calendar_16 = '/assets/img/icons/calendar_16.svg',
  Calendar_12 = '/assets/img/icons/calendar_12.svg',
  Filters_20 = '/assets/img/icons/filters_20.svg',
  Filters_16 = '/assets/img/icons/filters_16.svg',
  Filters_12 = '/assets/img/icons/filters_12.svg',
  FiltersOutline_24 = '/assets/img/icons/filters-outline_24.svg',
  FiltersOutline_16 = '/assets/img/icons/filters-outline_16.svg',
  FiltersOutline_12 = '/assets/img/icons/filters-outline_12.svg',
  Group_20 = '/assets/img/icons/group_20.svg',
  Group_16 = '/assets/img/icons/group_16.svg',
  Group_12 = '/assets/img/icons/group_12.svg',
  Columns_20 = '/assets/img/icons/columns_20.svg',
  Columns_16 = '/assets/img/icons/columns_16.svg',
  Columns_12 = '/assets/img/icons/columns_12.svg',
  Change_24 = '/assets/img/icons/change_24.svg',
  Change_16 = '/assets/img/icons/change_16.svg',
  Change_12 = '/assets/img/icons/change_12.svg',
  OrderOutline_24 = '/assets/img/icons/order-outline_24.svg',
  OrderOutline_16 = '/assets/img/icons/order-outline_16.svg',
  OrderOutline_12 = '/assets/img/icons/order-outline_12.svg',
  Order_24 = '/assets/img/icons/order_24.svg',
  Order_16 = '/assets/img/icons/order_16.svg',
  Order_12 = '/assets/img/icons/order_12.svg',
  Download_24 = '/assets/img/icons/download_24.svg',
  Download_16 = '/assets/img/icons/download_16.svg',
  Download_12 = '/assets/img/icons/download_12.svg',
  Download2_24 = '/assets/img/icons/download-2_24.svg',
  Download2_16 = '/assets/img/icons/download-2_16.svg',
  Download2_12 = '/assets/img/icons/download-2_12.svg',
  Upload_24 = '/assets/img/icons/upload_24.svg',
  Upload_16 = '/assets/img/icons/upload_16.svg',
  Upload_12 = '/assets/img/icons/upload_12.svg',
  File_24 = '/assets/img/icons/file_24.svg',
  File_16 = '/assets/img/icons/file_16.svg',
  File_12 = '/assets/img/icons/file_12.svg',
  FavoriteOutline_24 = '/assets/img/icons/favorite-outline_24.svg',
  FavoriteOutline_16 = '/assets/img/icons/favorite-outline_16.svg',
  FavoriteOutline_12 = '/assets/img/icons/favorite-outline_12.svg',
  Favorite_24 = '/assets/img/icons/favorite_24.svg',
  Favorite_16 = '/assets/img/icons/favorite_16.svg',
  Favorite_12 = '/assets/img/icons/favorite_12.svg',
  Login_24 = '/assets/img/icons/login_24.svg',
  Login_16 = '/assets/img/icons/login_16.svg',
  Login_12 = '/assets/img/icons/login_12.svg',
  Logout_24 = '/assets/img/icons/logout_24.svg',
  Logout_16 = '/assets/img/icons/logout_16.svg',
  Logout_12 = '/assets/img/icons/logout_12.svg',
  Mass_24 = '/assets/img/icons/mass_24.svg',
  Mass_16 = '/assets/img/icons/mass_16.svg',
  Mass_12 = '/assets/img/icons/mass_12.svg',
  Widget_32 = '/assets/img/icons/widget_32.svg',
  Widget_24 = '/assets/img/icons/widget_24.svg',
  Widget_16 = '/assets/img/icons/widget_16.svg',
  Widget_12 = '/assets/img/icons/widget_12.svg',
  Duplicate_24 = '/assets/img/icons/duplicate_24.svg',
  Duplicate_16 = '/assets/img/icons/duplicate_16.svg',
  Duplicate_12 = '/assets/img/icons/duplicate_12.svg',
  Check_24 = '/assets/img/icons/check_24.svg',
  Check_16 = '/assets/img/icons/check_16.svg',
  Check_12 = '/assets/img/icons/check_12.svg',
  CheckOutline_24 = '/assets/img/icons/check-outline_24.svg',
  CheckOutline_16 = '/assets/img/icons/check-outline_16.svg',
  CheckOutline_12 = '/assets/img/icons/check-outline_12.svg',
  Tree_24 = '/assets/img/icons/tree_24.svg',
  Tree_16 = '/assets/img/icons/tree_16.svg',
  Tree_12 = '/assets/img/icons/tree_12.svg',
  Remove_24 = '/assets/img/icons/remove_24.svg',
  Remove_16 = '/assets/img/icons/remove_16.svg',
  Remove_12 = '/assets/img/icons/remove_12.svg',
  Delete_24 = '/assets/img/icons/delete_24.svg',
  Delete_16 = '/assets/img/icons/delete_16.svg',
  Delete_12 = '/assets/img/icons/delete_12.svg',
  Delete2_24 = '/assets/img/icons/delete-2_24.svg',
  Delete2_16 = '/assets/img/icons/delete-2_16.svg',
  Delete2_12 = '/assets/img/icons/delete-2_12.svg',
  Cancel_24 = '/assets/img/icons/cancel_24.svg',
  Cancel_16 = '/assets/img/icons/cancel_16.svg',
  Cancel_12 = '/assets/img/icons/cancel_12.svg',
  Settings_24 = '/assets/img/icons/settings_24.svg',
  Settings_16 = '/assets/img/icons/settings_16.svg',
  Settings_12 = '/assets/img/icons/settings_12.svg',
  Hint_24 = '/assets/img/icons/hint_24.svg',
  Hint_16 = '/assets/img/icons/hint_16.svg',
  Hint_12 = '/assets/img/icons/hint_12.svg',
  Exchange_24 = '/assets/img/icons/exchange_24.svg',
  Exchange_16 = '/assets/img/icons/exchange_16.svg',
  Exchange_12 = '/assets/img/icons/exchange_12.svg',
  Deactivate_24 = '/assets/img/icons/deactivate_24.svg',
  Deactivate_16 = '/assets/img/icons/deactivate_16.svg',
  Deactivate_12 = '/assets/img/icons/deactivate_12.svg',
  User_24 = '/assets/img/icons/user_24.svg',
  User_16 = '/assets/img/icons/user_16.svg',
  User_12 = '/assets/img/icons/user_12.svg',
  UserOutline_12 = '/assets/img/icons/user_outline_12.svg',
  UserOutline_16 = '/assets/img/icons/user_outline_16.svg',
  UserOutline_24 = '/assets/img/icons/user_outline_24.svg',
  User2_24 = '/assets/img/icons/user-2_24.svg',
  User2_16 = '/assets/img/icons/user-2_16.svg',
  User2_12 = '/assets/img/icons/user-2_12.svg',
  Export_24 = '/assets/img/icons/export_24.svg',
  Export_16 = '/assets/img/icons/export_16.svg',
  Export_12 = '/assets/img/icons/export_12.svg',
  Import_24 = '/assets/img/icons/import_24.svg',
  Import_16 = '/assets/img/icons/import_16.svg',
  Import_12 = '/assets/img/icons/import_12.svg',
  Rotate_24 = '/assets/img/icons/rotate_24.svg',
  Rotate_16 = '/assets/img/icons/rotate_16.svg',
  Rotate_12 = '/assets/img/icons/rotate_12.svg',
  Update_24 = '/assets/img/icons/update_24.svg',
  Update_16 = '/assets/img/icons/update_16.svg',
  Update_12 = '/assets/img/icons/update_12.svg',
  Options_24 = '/assets/img/icons/options_24.svg',
  Options_16 = '/assets/img/icons/options_16.svg',
  Options_12 = '/assets/img/icons/options_12.svg',
  Save_24 = '/assets/img/icons/save_24.svg',
  Save_16 = '/assets/img/icons/save_16.svg',
  Save_12 = '/assets/img/icons/save_12.svg',
  SaveInProgress_24 = '/assets/img/icons/save-in-progress_24.svg',
  SaveInProgress_16 = '/assets/img/icons/save-in-progress_16.svg',
  SaveInProgress_12 = '/assets/img/icons/save-in-progress_12.svg',
  Saved_24 = '/assets/img/icons/saved_24.svg',
  Saved_16 = '/assets/img/icons/saved_16.svg',
  Saved_12 = '/assets/img/icons/saved_12.svg',
  ColumnsRollup_24 = '/assets/img/icons/columns-rollup_24.svg',
  ColumnsRollup_16 = '/assets/img/icons/columns-rollup_16.svg',
  ColumnsRollup_12 = '/assets/img/icons/columns-rollup_12.svg',
  ColumnsExpand_24 = '/assets/img/icons/columns-expand_24.svg',
  ColumnsExpand_16 = '/assets/img/icons/columns-expand_16.svg',
  ColumnsExpand_12 = '/assets/img/icons/columns-expand_12.svg',
  Refresh_24 = '/assets/img/icons/refresh_24.svg',
  Refresh_16 = '/assets/img/icons/refresh_16.svg',
  Refresh_12 = '/assets/img/icons/refresh_12.svg',
  Reset_24 = '/assets/img/icons/reset_24.svg',
  Reset_16 = '/assets/img/icons/reset_16.svg',
  Reset_12 = '/assets/img/icons/reset_12.svg',
  PlusOutline = '/assets/img/icons/add-next.svg',
  ArrowUpSharp_21x21 = '/assets/img/icons/arrow-up-2.svg',
  ArrowDownSharp_21x21 = '/assets/img/icons/arrow-down-2.svg',
  ArrowRightSharp_16x16 = '/assets/img/icons/arrow-right-3-16x16.svg',
  ArrowLeftTriangleCircle_32x32 = '/assets/img/icons/arrow-left-2-24x24.svg',
  ArrowRightTriangleCircle_32x32 = '/assets/img/icons/arrow-right-4-24x24.svg',
  ArrowLeftSharpCircle = '/assets/img/icons/arrow-left-circle.svg',
  ArrowLeftSharpCircle2 = '/assets/img/icons/round-arrow.svg',
  ArrowUpSharpCurve = '/assets/img/icons/arrow-up-4.svg',
  Allocate = '/assets/img/icons/allocate.svg',
  Assign = '/assets/img/icons/assign.svg',
  StatusAttentionRectangle_24 = '/assets/img/icons/status-attention-rectangle_24.svg',
  StatusAttentionRectangle_16 = '/assets/img/icons/status-attention-rectangle_16.svg',
  StatusAttentionRectangle_12 = '/assets/img/icons/status-attention-rectangle_12.svg',
  StatusAttentionCircle_24 = '/assets/img/icons/status-attention-circle_24.svg',
  StatusAttentionCircle_16 = '/assets/img/icons/status-attention-circle_16.svg',
  StatusAttentionCircle_12 = '/assets/img/icons/status-attention-circle_12.svg',
  StatusSuccessRectangle_24 = '/assets/img/icons/status-success-rectangle_24.svg',
  StatusSuccessRectangle_16 = '/assets/img/icons/status-success-rectangle_16.svg',
  StatusSuccessRectangle_12 = '/assets/img/icons/status-success-rectangle_12.svg',
  StatusSuccessCircle_24 = '/assets/img/icons/status-success-circle_24.svg',
  StatusSuccessCircle_16 = '/assets/img/icons/status-success-circle_16.svg',
  StatusSuccessCircle_12 = '/assets/img/icons/status-success-circle_12.svg',
  StatusWaitingRectangle_24 = '/assets/img/icons/status-waiting-rectangle_24.svg',
  StatusWaitingRectangle_16 = '/assets/img/icons/status-waiting-rectangle_16.svg',
  StatusWaitingRectangle_12 = '/assets/img/icons/status-waiting-rectangle_12.svg',
  StatusWaitingCircle_24 = '/assets/img/icons/status-waiting-circle_24.svg',
  StatusWaitingCircle_16 = '/assets/img/icons/status-waiting-circle_16.svg',
  StatusWaitingCircle_12 = '/assets/img/icons/status-waiting-circle_12.svg',
  StatusDisproveRectangle_24 = '/assets/img/icons/status-disprove-rectangle_24.svg',
  StatusDisproveRectangle_16 = '/assets/img/icons/status-disprove-rectangle_16.svg',
  StatusDisproveRectangle_12 = '/assets/img/icons/status-disprove-rectangle_12.svg',
  StatusDisproveCircle_24 = '/assets/img/icons/status-disprove-circle_24.svg',
  StatusDisproveCircle_16 = '/assets/img/icons/status-disprove-circle_16.svg',
  StatusDisproveCircle_12 = '/assets/img/icons/status-disprove-circle_12.svg',
  SuccessToast = '/assets/img/icons/success-toast.svg',
  Back2 = '/assets/img/icons/back-2.svg',
  Clear = '/assets/img/icons/clear.svg',
  Reset = '/assets/img/icons/reset.svg',
  UncheckedOutline_12x12 = '/assets/img/icons/workflow-disabled.svg',
  Edit4 = '/assets/img/icons/edit-4.svg',
  Enter = '/assets/img/icons/enter.svg',
  ExportPdf = '/assets/img/icons/export-doc.svg',
  Doc = '/assets/img/icons/doc.svg',
  ExportDocs = '/assets/img/icons/export-docs.svg',
  Feedback = '/assets/img/icons/feedback.svg',
  FromArrow = '/assets/img/icons/from-arrow.svg',
  FullWindow = '/assets/img/icons/full-window.svg',
  MiddleWindow = '/assets/img/icons/middle-window.svg',
  SideWindow = '/assets/img/icons/side-window.svg',
  Group2 = '/assets/img/icons/group-2.svg',
  HiddenIcons = '/assets/img/icons/hidden-icons.svg',
  Money = '/assets/img/icons/money.svg',
  NewComment = '/assets/img/icons/new-comment.svg',
  NewWindow = '/assets/img/icons/new-window.svg',
  NotificationUnreadFill = '/assets/img/icons/notification-unread-fill.svg',
  NotificationUnread = '/assets/img/icons/notification-unread.svg',
  Notification = '/assets/img/icons/notification.svg',
  Pin = '/assets/img/icons/pin.svg',
  Navigation = '/assets/img/icons/portfolio-navigation.svg',
  NavigationList = '/assets/img/icons/portfolio-navigation-list.svg',
  PushIconArrows = '/assets/img/icons/push-icon-arrows.svg',
  SettingsHorizontalOutline = '/assets/img/icons/settings-horizontal-outline.svg',
  StrategyDefaultIcon = '/assets/img/icons/strategy-default-icon.svg',
  Swap2 = '/assets/img/icons/swap-2.svg',
  ToArrow = '/assets/img/icons/to-arrow.svg',
  Transaction = '/assets/img/icons/transaction-icon.svg',
  Views = '/assets/img/icons/views.svg',
  BetweenOperator = '/assets/img/operators/between.svg',
  ContainsOperator = '/assets/img/operators/contains.svg',
  DoesNotContainOperator = '/assets/img/operators/does-not-contain.svg',
  DoesNotEqualOperator = '/assets/img/operators/does-not-equal.svg',
  EndsWithOperator = '/assets/img/operators/ends-with.svg',
  EqualsOperator = '/assets/img/operators/equals.svg',
  GreaterThanOrEqualOperator = '/assets/img/operators/greater-than-or-equal.svg',
  GreaterThanOperator = '/assets/img/operators/greater-than.svg',
  IsNotNullOperator = '/assets/img/operators/is-not-null.svg',
  IsNullOperator = '/assets/img/operators/is-null.svg',
  LessThanOrEqualOperator = '/assets/img/operators/less-than-or-equal.svg',
  LessThanOperator = '/assets/img/operators/less-than.svg',
  ResetOperator = '/assets/img/operators/reset.svg',
  StartsWithOperator = '/assets/img/operators/starts-with.svg',
  ImageIcon = '/assets/img/icons/image-icon.svg',
  Bell = '/assets/img/icons/bell.svg',
  Safe = '/assets/img/icons/safe.svg',
  Portfolio = '/assets/img/icons/portfolio.svg',
  PushNotificationIcon1 = '/assets/img/push-notification/push-notification-icon-1.svg',
  PushNotificationUnreadIcon1 = '/assets/img/push-notification/push-notification-icon-1-unread.svg',
  PushNotificationIcon2 = '/assets/img/push-notification/push-notification-icon-2.svg',
  PushNotificationUnreadIcon2 = '/assets/img/push-notification/push-notification-icon-2-unread.svg',
  PushNotificationIcon3 = '/assets/img/push-notification/push-notification-icon-3.svg',
  PushNotificationUnreadIcon3 = '/assets/img/push-notification/push-notification-icon-3-unread.svg',
  PushNotificationIcon4 = '/assets/img/push-notification/push-notification-icon-4.svg',
  PushNotificationUnreadIcon4 = '/assets/img/push-notification/push-notification-icon-4-unread.svg',
  PushNotificationIcon5 = '/assets/img/push-notification/push-notification-icon-5.svg',
  PushNotificationUnreadIcon5 = '/assets/img/push-notification/push-notification-icon-5-unread.svg',
  PushNotificationIcon6 = '/assets/img/push-notification/push-notification-icon-6.svg',
  PushNotificationUnreadIcon6 = '/assets/img/push-notification/push-notification-icon-6-unread.svg',
  PushNotificationIcon7 = '/assets/img/push-notification/push-notification-icon-7.svg',
  PushNotificationUnreadIcon7 = '/assets/img/push-notification/push-notification-icon-7-unread.svg',
  PushNotificationIcon8 = '/assets/img/push-notification/push-notification-icon-8.svg',
  PushNotificationUnreadIcon8 = '/assets/img/push-notification/push-notification-icon-8-unread.svg',
  PushNotificationIcon9 = '/assets/img/push-notification/push-notification-icon-9.svg',
  PushNotificationUnreadIcon9 = '/assets/img/push-notification/push-notification-icon-9-unread.svg',
  PushNotificationIcon10 = '/assets/img/push-notification/push-notification-icon-10.svg',
  PushNotificationUnreadIcon10 = '/assets/img/push-notification/push-notification-icon-10-unread.svg',
  Antiques = '/assets/img/private-assets/antiques.svg',
  AntiquesSmall = '/assets/img/private-assets/antiques-small.svg',
  Car = '/assets/img/private-assets/car.svg',
  CarSmall = '/assets/img/private-assets/car-small.svg',
  Card = '/assets/img/private-assets/card.svg',
  CardSmall = '/assets/img/private-assets/card-small.svg',
  Classifications = '/assets/img/private-assets/classifications.svg',
  ClassificationsSmall = '/assets/img/private-assets/classifications-small.svg',
  Commodities = '/assets/img/private-assets/commodities.svg',
  CommoditiesSmall = '/assets/img/private-assets/commodities-small.svg',
  Helicopter = '/assets/img/private-assets/helicopter.svg',
  HelicopterSmall = '/assets/img/private-assets/helicopter-small.svg',
  Portfolio2 = '/assets/img/private-assets/portfolio.svg',
  PortfolioSmall = '/assets/img/private-assets/portfolio-small.svg',
  RealEstate = '/assets/img/private-assets/real-estate.svg',
  RealEstateSmall = '/assets/img/private-assets/real-estate-small.svg',
  Yacht = '/assets/img/private-assets/yacht.svg',
  YachtSmall = '/assets/img/private-assets/yacht-small.svg',
  Accumulation = '/assets/img/icons/accumulation.svg',
  Payment = '/assets/img/icons/payment.svg',
  PrivateEquities = '/assets/img/icons/private-equities.svg',
  Redemption = '/assets/img/icons/redemption.svg',
  ResidentialProperty = '/assets/img/icons/residential-property.svg',
  HedgeFund = '/assets/img/icons/hedge-fund.svg',
  StructuralProduct = '/assets/img/icons/structural-product.svg',
  Strategy = '/assets/img/icons/strategy.svg',
  YachtOld = '/assets/img/icons/yacht.svg',
  Aircraft = '/assets/img/icons/aircraft.svg',
  ComercialProperty = '/assets/img/icons/comercial-property.svg',
  CommoditiesOld = '/assets/img/icons/commodities.svg',
  IndividualAccountColored32 = '/assets/img/entities/individual-account_colored_32.svg',
  IndividualAccountGroupColored32 = '/assets/img/entities/individual-account-group_colored_32.svg',
  Portfolio12 = '/assets/img/entities/portfolio_12.svg',
  Portfolio16 = '/assets/img/entities/portfolio_16.svg',
  Portfolio24 = '/assets/img/entities/portfolio_24.svg',
  PortfolioColored32 = '/assets/img/entities/portfolio_colored_32.svg',
  PortfolioGroupColored32 = '/assets/img/entities/portfolio-group_colored_32.svg',
  PortfolioGroup12 = '/assets/img/entities/portfolio-group_12.svg',
  PortfolioGroup16 = '/assets/img/entities/portfolio-group_16.svg',
  PortfolioGroup24 = '/assets/img/entities/portfolio-group_24.svg',
  VirtualPortfolio12 = '/assets/img/entities/virtual-portfolio_12.svg',
  VirtualPortfolio16 = '/assets/img/entities/virtual-portfolio_16.svg',
  VirtualPortfolio24 = '/assets/img/entities/virtual-portfolio_24.svg',
  BankAccount12 = '/assets/img/entities/bank-account_12.svg',
  BankAccount16 = '/assets/img/entities/bank-account_16.svg',
  BankAccount24 = '/assets/img/entities/bank-account_24.svg',
  BankAccountColored32 = '/assets/img/entities/bank-account_colored_32.svg',
  BankAccountGroupColored32 = '/assets/img/entities/bank-account-group_colored_32.svg',
  Entity12 = '/assets/img/entities/entity_12.svg',
  Entity16 = '/assets/img/entities/entity_16.svg',
  Entity24 = '/assets/img/entities/entity_24.svg',
  EntityColored32 = '/assets/img/entities/entity_colored_32.svg',
  EntityGroupColored32 = '/assets/img/entities/entity-group_colored_32.svg',
  RealEstate12 = '/assets/img/entities/real-estate_12.svg',
  RealEstate16 = '/assets/img/entities/real-estate_16.svg',
  RealEstate24 = '/assets/img/entities/real-estate_24.svg',
  RealEstateColored32 = '/assets/img/entities/real-estate_colored_32.svg',
  RealEstateGroupColored32 = '/assets/img/entities/real-estate-group_colored_32.svg',
  Antiques12 = '/assets/img/entities/antiques_12.svg',
  Antiques16 = '/assets/img/entities/antiques_16.svg',
  Antiques24 = '/assets/img/entities/antiques_24.svg',
  AntiquesColored32 = '/assets/img/entities/antiques_colored_32.svg',
  AntiquesGroupColored32 = '/assets/img/entities/antiques-group_colored_32.svg',
  Safe12 = '/assets/img/entities/safe_12.svg',
  Safe16 = '/assets/img/entities/safe_16.svg',
  Safe24 = '/assets/img/entities/safe_24.svg',
  SafeColored32 = '/assets/img/entities/safe_colored_32.svg',
  SafeGroupColored32 = '/assets/img/entities/safe-group_colored_32.svg',
  PrivateEquity12 = '/assets/img/entities/private-equity_12.svg',
  PrivateEquity16 = '/assets/img/entities/private-equity_16.svg',
  PrivateEquity24 = '/assets/img/entities/private-equity_24.svg',
  PrivateEquityColored32 = '/assets/img/entities/private-equity_colored_32.svg',
  PrivateEquityGroupColored32 = '/assets/img/entities/private-equity-group_colored_32.svg',
  Card12 = '/assets/img/entities/card_12.svg',
  Card16 = '/assets/img/entities/card_16.svg',
  Card24 = '/assets/img/entities/card_24.svg',
  CardColored32 = '/assets/img/entities/card_colored_32.svg',
  CardGroupColored32 = '/assets/img/entities/card-group_colored_32.svg',
  Car12 = '/assets/img/entities/car_12.svg',
  Car16 = '/assets/img/entities/car_16.svg',
  Car24 = '/assets/img/entities/car_24.svg',
  CarColored32 = '/assets/img/entities/car_colored_32.svg',
  CarGroupColored32 = '/assets/img/entities/car-group_colored_32.svg',
  Yacht12 = '/assets/img/entities/yacht_12.svg',
  Yacht16 = '/assets/img/entities/yacht_16.svg',
  Yacht24 = '/assets/img/entities/yacht_24.svg',
  YachtColored32 = '/assets/img/entities/yacht_colored_32.svg',
  YachtGroupColored32 = '/assets/img/entities/yacht-group_colored_32.svg',
  Helicopter12 = '/assets/img/entities/helicopter_12.svg',
  Helicopter16 = '/assets/img/entities/helicopter_16.svg',
  Helicopter24 = '/assets/img/entities/helicopter_24.svg',
  HelicopterColored32 = '/assets/img/entities/helicopter_colored_32.svg',
  HelicopterGroupColored32 = '/assets/img/entities/helicopter-group_colored_32.svg',
  Tangible12 = '/assets/img/entities/tangible_12.svg',
  Tangible16 = '/assets/img/entities/tangible_16.svg',
  Tangible24 = '/assets/img/entities/tangible_24.svg',
  TangibleColored32 = '/assets/img/entities/tangible_colored_32.svg',
  TangibleGroupColored32 = '/assets/img/entities/tangible-group_colored_32.svg',
  Intangible12 = '/assets/img/entities/intangible_12.svg',
  Intangible16 = '/assets/img/entities/intangible_16.svg',
  Intangible24 = '/assets/img/entities/intangible_24.svg',
  IntangibleColored32 = '/assets/img/entities/intangible_colored_32.svg',
  IntangibleGroupColored32 = '/assets/img/entities/intangible-group_colored_32.svg',
  CommonAsset32 = '/assets/img/entities/common-asset_32.svg',
  CommonAssetGroup32 = '/assets/img/entities/common-asset-group_32.svg',
  Adjustment12 = '/assets/img/transactions/adjustment_12.svg',
  Adjustment16 = '/assets/img/transactions/adjustment_16.svg',
  Adjustment24 = '/assets/img/transactions/adjustment_24.svg',
  Block12 = '/assets/img/transactions/block_12.svg',
  Block16 = '/assets/img/transactions/block_16.svg',
  Block24 = '/assets/img/transactions/block_24.svg',
  BondRedemption12 = '/assets/img/transactions/bond-redemption_12.svg',
  BondRedemption16 = '/assets/img/transactions/bond-redemption_16.svg',
  BondRedemption24 = '/assets/img/transactions/bond-redemption_24.svg',
  BondRedemptionPartial12 = '/assets/img/transactions/bond-redemption-partial_12.svg',
  BondRedemptionPartial16 = '/assets/img/transactions/bond-redemption-partial_16.svg',
  BondRedemptionPartial24 = '/assets/img/transactions/bond-redemption-partial_24.svg',
  CashTransfer12 = '/assets/img/transactions/cash-transfer_12.svg',
  CashTransfer16 = '/assets/img/transactions/cash-transfer_16.svg',
  CashTransfer24 = '/assets/img/transactions/cash-transfer_24.svg',
  CorporateAction12 = '/assets/img/transactions/corporate-action_12.svg',
  CorporateAction16 = '/assets/img/transactions/corporate-action_16.svg',
  CorporateAction24 = '/assets/img/transactions/corporate-action_24.svg',
  ExerciseOption12 = '/assets/img/transactions/exercise-option_12.svg',
  ExerciseOption16 = '/assets/img/transactions/exercise-option_16.svg',
  ExerciseOption24 = '/assets/img/transactions/exercise-option_24.svg',
  ExpireOption12 = '/assets/img/transactions/expire-option_12.svg',
  ExpireOption16 = '/assets/img/transactions/expire-option_16.svg',
  ExpireOption24 = '/assets/img/transactions/expire-option_24.svg',
  Expense12 = '/assets/img/transactions/expense_12.svg',
  Expense16 = '/assets/img/transactions/expense_16.svg',
  Expense24 = '/assets/img/transactions/expense_24.svg',
  Fee12 = '/assets/img/transactions/fee_12.svg',
  Fee16 = '/assets/img/transactions/fee_16.svg',
  Fee24 = '/assets/img/transactions/fee_24.svg',
  FxCredit12 = '/assets/img/transactions/fx-credit_12.svg',
  FxCredit16 = '/assets/img/transactions/fx-credit_16.svg',
  FxCredit24 = '/assets/img/transactions/fx-credit_24.svg',
  FxDebit12 = '/assets/img/transactions/fx-debit_12.svg',
  FxDebit16 = '/assets/img/transactions/fx-debit_16.svg',
  FxDebit24 = '/assets/img/transactions/fx-debit_24.svg',
  Income12 = '/assets/img/transactions/income_12.svg',
  Income16 = '/assets/img/transactions/income_16.svg',
  Income24 = '/assets/img/transactions/income_24.svg',
  Info12 = '/assets/img/transactions/info_12.svg',
  Info16 = '/assets/img/transactions/info_16.svg',
  Info24 = '/assets/img/transactions/info_24.svg',
  Interest12 = '/assets/img/transactions/interest_12.svg',
  Interest16 = '/assets/img/transactions/interest_16.svg',
  Interest24 = '/assets/img/transactions/interest_24.svg',
  InternalTransfer12 = '/assets/img/transactions/internal-transfer_12.svg',
  InternalTransfer16 = '/assets/img/transactions/internal-transfer_16.svg',
  InternalTransfer24 = '/assets/img/transactions/internal-transfer_24.svg',
  Invest12 = '/assets/img/transactions/invest_12.svg',
  Invest16 = '/assets/img/transactions/invest_16.svg',
  Invest24 = '/assets/img/transactions/invest_24.svg',
  Margin12 = '/assets/img/transactions/margin_12.svg',
  Margin16 = '/assets/img/transactions/margin_16.svg',
  Margin24 = '/assets/img/transactions/margin_24.svg',
  Payment12 = '/assets/img/transactions/payment_12.svg',
  Payment16 = '/assets/img/transactions/payment_16.svg',
  Payment24 = '/assets/img/transactions/payment_24.svg',
  Subscribe12 = '/assets/img/transactions/subscribe_12.svg',
  Subscribe16 = '/assets/img/transactions/subscribe_16.svg',
  Subscribe24 = '/assets/img/transactions/subscribe_24.svg',
  Unsubscribe12 = '/assets/img/transactions/unsubscribe_12.svg',
  Unsubscribe16 = '/assets/img/transactions/unsubscribe_16.svg',
  Unsubscribe24 = '/assets/img/transactions/unsubscribe_24.svg',
  Repo12 = '/assets/img/transactions/repo_12.svg',
  Repo16 = '/assets/img/transactions/repo_16.svg',
  Repo24 = '/assets/img/transactions/repo_24.svg',
  Revenue12 = '/assets/img/transactions/revenue_12.svg',
  Revenue16 = '/assets/img/transactions/revenue_16.svg',
  Revenue24 = '/assets/img/transactions/revenue_24.svg',
  Reversal12 = '/assets/img/transactions/reversal_12.svg',
  Reversal16 = '/assets/img/transactions/reversal_16.svg',
  Reversal24 = '/assets/img/transactions/reversal_24.svg',
  SecurityTransfer12 = '/assets/img/transactions/security-transfer_12.svg',
  SecurityTransfer16 = '/assets/img/transactions/security-transfer_16.svg',
  SecurityTransfer24 = '/assets/img/transactions/security-transfer_24.svg',
  Tax12 = '/assets/img/transactions/tax_12.svg',
  Tax16 = '/assets/img/transactions/tax_16.svg',
  Tax24 = '/assets/img/transactions/tax_24.svg',
  Transfer12 = '/assets/img/transactions/transfer_12.svg',
  Transfer16 = '/assets/img/transactions/transfer_16.svg',
  Transfer24 = '/assets/img/transactions/transfer_24.svg',
  Buy12 = '/assets/img/transactions/buy_12.svg',
  Buy16 = '/assets/img/transactions/buy_16.svg',
  Buy24 = '/assets/img/transactions/buy_24.svg',
  Sell12 = '/assets/img/transactions/sell_12.svg',
  Sell16 = '/assets/img/transactions/sell_16.svg',
  Sell24 = '/assets/img/transactions/sell_24.svg',
  Unknown12 = '/assets/img/transactions/unknown_12.svg',
  Unknown16 = '/assets/img/transactions/unknown_16.svg',
  Unknown24 = '/assets/img/transactions/unknown_24.svg',
  VirtualSecurityTransfer12 = '/assets/img/transactions/virtual-security-transfer_12.svg',
  VirtualSecurityTransfer16 = '/assets/img/transactions/virtual-security-transfer_16.svg',
  VirtualSecurityTransfer24 = '/assets/img/transactions/virtual-security-transfer_24.svg',
  Withdraw12 = '/assets/img/transactions/withdraw_12.svg',
  Withdraw16 = '/assets/img/transactions/withdraw_16.svg',
  Withdraw24 = '/assets/img/transactions/withdraw_24.svg',
}

export const IconSvgTypes: Partial<Record<IconNames, SvgComponent['type']>> = {
  ArrowShortLeft_24: 'stroke',
  ArrowShortLeft_16: 'stroke',
  ArrowShortLeft_12: 'stroke',
  ArrowShortRight_24: 'stroke',
  ArrowShortRight_16: 'stroke',
  ArrowShortRight_12: 'stroke',
  ArrowShortTop_24: 'stroke',
  ArrowShortTop_16: 'stroke',
  ArrowShortTop_12: 'stroke',
  ArrowShortDown_24: 'stroke',
  ArrowShortDown_16: 'stroke',
  ArrowShortDown_12: 'stroke',
  Forward_24: 'stroke',
  Forward_16: 'stroke',
  Forward_12: 'stroke',
  Expand_24: 'stroke',
  Expand_16: 'stroke',
  Expand_12: 'stroke',
  Rollup_24: 'stroke',
  Rollup_16: 'stroke',
  Rollup_12: 'stroke',
  Back_24: 'stroke',
  Back_16: 'stroke',
  Back_12: 'stroke',
  Done_24: 'stroke',
  Done_16: 'stroke',
  Done_12: 'stroke',
  Close_24: 'stroke',
  Close_16: 'stroke',
  Close_12: 'stroke',
  Add_24: 'stroke',
  Add_16: 'stroke',
  Add_12: 'stroke',
  Skip_24: 'stroke',
  Skip_16: 'stroke',
  Skip_12: 'stroke',
  Edit_24: 'stroke',
  Edit_16: 'stroke',
  Edit_12: 'stroke',
  ShareSquare_24: 'stroke',
  ShareSquare_16: 'stroke',
  ShareSquare_12: 'stroke',
  ShareOutline_24: 'stroke',
  ShareOutline_16: 'stroke',
  ShareOutline_12: 'stroke',
  Link_24: 'stroke',
  Link_16: 'stroke',
  Link_12: 'stroke',
  Save_24: 'stroke',
  Save_16: 'stroke',
  Save_12: 'stroke',
  Saved_24: 'stroke',
  Saved_16: 'stroke',
  Saved_12: 'stroke',
  Refresh_24: 'stroke',
  Refresh_16: 'stroke',
  Refresh_12: 'stroke',
  Reset_24: 'stroke',
  Reset_16: 'stroke',
  Reset_12: 'stroke',
  SaveInProgress_24: 'stroke',
  SaveInProgress_16: 'stroke',
  SaveInProgress_12: 'stroke',
  ShareLink_24: 'stroke',
  ShareLink_16: 'stroke',
  ShareLink_12: 'stroke',
  OpenInNewWindow_24: 'stroke',
  OpenInNewWindow_16: 'stroke',
  OpenInNewWindow_12: 'stroke',
  Hide_24: 'stroke',
  Hide_16: 'stroke',
  Hide_12: 'stroke',
  Export_24: 'stroke',
  Export_16: 'stroke',
  Export_12: 'stroke',
  Import_24: 'stroke',
  Import_16: 'stroke',
  Import_12: 'stroke',
  Rotate_24: 'stroke',
  Rotate_16: 'stroke',
  Rotate_12: 'stroke',
  Update_24: 'stroke',
  Update_16: 'stroke',
  Update_12: 'stroke',
  FiltersOutline_24: 'stroke',
  FiltersOutline_16: 'stroke',
  FiltersOutline_12: 'stroke',
  Transaction_24: 'stroke',
  Transaction_16: 'stroke',
  Transaction_12: 'stroke',
  Change_24: 'stroke',
  Change_16: 'stroke',
  Change_12: 'stroke',
  Download_24: 'stroke',
  Download_16: 'stroke',
  Download_12: 'stroke',
  Download2_24: 'stroke',
  Download2_16: 'stroke',
  Download2_12: 'stroke',
  Upload_24: 'stroke',
  Upload_16: 'stroke',
  Upload_12: 'stroke',
  File_24: 'stroke',
  File_16: 'stroke',
  File_12: 'stroke',
  Tree_24: 'stroke',
  Tree_16: 'stroke',
  Tree_12: 'stroke',
  Options_24: 'stroke',
  Options_16: 'stroke',
  Options_12: 'stroke',
  Delete_24: 'stroke',
  Delete_16: 'stroke',
  Delete_12: 'stroke',
  Delete2_24: 'stroke',
  Delete2_16: 'stroke',
  Delete2_12: 'stroke',
  Cancel_24: 'stroke',
  Cancel_16: 'stroke',
  Cancel_12: 'stroke',
  Settings_24: 'stroke',
  Settings_16: 'stroke',
  Settings_12: 'stroke',
  Exchange_24: 'stroke',
  Exchange_16: 'stroke',
  Exchange_12: 'stroke',
  Deactivate_24: 'stroke',
  Deactivate_16: 'stroke',
  Deactivate_12: 'stroke',
  CheckOutline_24: 'stroke',
  CheckOutline_16: 'stroke',
  CheckOutline_12: 'stroke',
  FavoriteOutline_24: 'stroke',
  FavoriteOutline_16: 'stroke',
  FavoriteOutline_12: 'stroke',
  OrderOutline_24: 'stroke',
  OrderOutline_16: 'stroke',
  OrderOutline_12: 'stroke',
  ArrowUpSharp_21x21: 'stroke',
  ArrowDownSharp_21x21: 'stroke',
  ArrowRightSharp_16x16: 'stroke',
  Allocate: 'stroke',
  Assign: 'stroke',
  Edit4: 'stroke',
  FromArrow: 'stroke',
  FullWindow: 'skeleton',
  MiddleWindow: 'skeleton',
  SideWindow: 'skeleton',
  Money: 'stroke',
  NewComment: 'skeleton',
  NewWindow: 'stroke',
  SettingsHorizontalOutline: 'stroke',
  ToArrow: 'stroke',
  Views: 'stroke',
  UncheckedOutline_12x12: 'stroke',
  Aircraft: 'skeleton',
  Portfolio: 'none',
  Safe: 'none',
  Antiques: 'none',
  AntiquesSmall: 'none',
  Car: 'none',
  CarSmall: 'none',
  Card: 'none',
  CardSmall: 'none',
  Classifications: 'none',
  ClassificationsSmall: 'none',
  Commodities: 'none',
  CommoditiesSmall: 'none',
  Helicopter: 'none',
  HelicopterSmall: 'none',
  Portfolio2: 'none',
  PortfolioSmall: 'none',
  RealEstate: 'none',
  RealEstateSmall: 'none',
  Yacht: 'none',
  YachtSmall: 'none',
  PushNotificationUnreadIcon1: 'skeleton',
  PushNotificationIcon2: 'none',
  PushNotificationUnreadIcon2: 'none',
  PushNotificationIcon3: 'none',
  PushNotificationUnreadIcon3: 'none',
  PushNotificationIcon4: 'none',
  PushNotificationUnreadIcon4: 'none',
  PushNotificationIcon5: 'none',
  PushNotificationUnreadIcon5: 'none',
  PushNotificationIcon6: 'none',
  PushNotificationUnreadIcon6: 'none',
  PushNotificationIcon7: 'none',
  PushNotificationUnreadIcon7: 'none',
  PushNotificationIcon8: 'none',
  PushNotificationUnreadIcon8: 'none',
  PushNotificationIcon9: 'none',
  PushNotificationUnreadIcon9: 'none',
  PushNotificationIcon10: 'none',
  PushNotificationUnreadIcon10: 'none',
  UserOutline_12: 'stroke',
  UserOutline_16: 'stroke',
  UserOutline_24: 'stroke',
  Portfolio12: 'stroke',
  Portfolio16: 'stroke',
  Portfolio24: 'stroke',
  PortfolioColored32: 'none',
  PortfolioGroupColored32: 'none',
  PortfolioGroup12: 'stroke',
  PortfolioGroup16: 'stroke',
  PortfolioGroup24: 'stroke',
  VirtualPortfolio12: 'stroke',
  VirtualPortfolio16: 'stroke',
  VirtualPortfolio24: 'stroke',
  BankAccount12: 'stroke',
  BankAccount16: 'stroke',
  BankAccount24: 'stroke',
  BankAccountColored32: 'none',
  BankAccountGroupColored32: 'none',
  IndividualAccountColored32: 'none',
  IndividualAccountGroupColored32: 'none',
  Entity12: 'stroke',
  Entity16: 'stroke',
  Entity24: 'stroke',
  EntityColored32: 'none',
  EntityGroupColored32: 'none',
  RealEstate12: 'stroke',
  RealEstate16: 'stroke',
  RealEstate24: 'stroke',
  RealEstateColored32: 'none',
  RealEstateGroupColored32: 'none',
  Card12: 'stroke',
  Card16: 'stroke',
  Card24: 'stroke',
  CarColored32: 'none',
  Car12: 'stroke',
  Car16: 'stroke',
  Car24: 'stroke',
  CarGroupColored32: 'none',
  Antiques12: 'stroke',
  Antiques16: 'stroke',
  Antiques24: 'stroke',
  AntiquesColored32: 'none',
  AntiquesGroupColored32: 'none',
  Safe12: 'stroke',
  Safe16: 'stroke',
  Safe24: 'stroke',
  SafeColored32: 'none',
  SafeGroupColored32: 'none',
  PrivateEquity12: 'stroke',
  PrivateEquity16: 'stroke',
  PrivateEquity24: 'stroke',
  PrivateEquityColored32: 'none',
  PrivateEquityGroupColored32: 'none',
  CardColored32: 'none',
  CardGroupColored32: 'none',
  Yacht12: 'stroke',
  Yacht16: 'stroke',
  Yacht24: 'stroke',
  YachtColored32: 'none',
  YachtGroupColored32: 'none',
  Helicopter12: 'stroke',
  Helicopter16: 'stroke',
  Helicopter24: 'stroke',
  HelicopterColored32: 'none',
  HelicopterGroupColored32: 'none',
  Tangible12: 'stroke',
  Tangible16: 'stroke',
  Tangible24: 'stroke',
  TangibleColored32: 'none',
  TangibleGroupColored32: 'none',
  Intangible12: 'stroke',
  Intangible16: 'stroke',
  Intangible24: 'stroke',
  IntangibleColored32: 'none',
  IntangibleGroupColored32: 'none',
  CommonAsset32: 'skeleton',
  CommonAssetGroup32: 'skeleton',
  Adjustment12: 'stroke',
  Adjustment16: 'stroke',
  Adjustment24: 'stroke',
  Block12: 'stroke',
  Block16: 'stroke',
  Block24: 'stroke',
  BondRedemption12: 'stroke',
  BondRedemption16: 'stroke',
  BondRedemption24: 'stroke',
  BondRedemptionPartial12: 'stroke',
  BondRedemptionPartial16: 'stroke',
  BondRedemptionPartial24: 'stroke',
  CashTransfer12: 'stroke',
  CashTransfer16: 'stroke',
  CashTransfer24: 'stroke',
  CorporateAction12: 'stroke',
  CorporateAction16: 'stroke',
  CorporateAction24: 'stroke',
  ExerciseOption12: 'stroke',
  ExerciseOption16: 'stroke',
  ExerciseOption24: 'stroke',
  ExpireOption12: 'stroke',
  ExpireOption16: 'stroke',
  ExpireOption24: 'stroke',
  Fee12: 'stroke',
  Fee16: 'stroke',
  Fee24: 'stroke',
  FxCredit12: 'stroke',
  FxCredit16: 'stroke',
  FxCredit24: 'stroke',
  FxDebit12: 'stroke',
  FxDebit16: 'stroke',
  FxDebit24: 'stroke',
  Income12: 'stroke',
  Income16: 'stroke',
  Income24: 'stroke',
  Info12: 'stroke',
  Info16: 'stroke',
  Info24: 'stroke',
  Interest12: 'stroke',
  Interest16: 'stroke',
  Interest24: 'stroke',
  InternalTransfer12: 'stroke',
  InternalTransfer16: 'stroke',
  InternalTransfer24: 'stroke',
  Invest12: 'stroke',
  Invest16: 'stroke',
  Invest24: 'stroke',
  Payment12: 'stroke',
  Payment16: 'stroke',
  Payment24: 'stroke',
  Repo12: 'stroke',
  Repo16: 'stroke',
  Repo24: 'stroke',
  Revenue12: 'stroke',
  Revenue16: 'stroke',
  Revenue24: 'stroke',
  Reversal12: 'stroke',
  Reversal16: 'stroke',
  Reversal24: 'stroke',
  SecurityTransfer12: 'stroke',
  SecurityTransfer16: 'stroke',
  SecurityTransfer24: 'stroke',
  Sell12: 'stroke',
  Sell16: 'stroke',
  Sell24: 'stroke',
  Tax12: 'stroke',
  Tax16: 'stroke',
  Tax24: 'stroke',
  Transfer12: 'stroke',
  Transfer16: 'stroke',
  Transfer24: 'stroke',
  Unknown12: 'stroke',
  Unknown16: 'stroke',
  Unknown24: 'stroke',
  VirtualSecurityTransfer12: 'stroke',
  VirtualSecurityTransfer16: 'stroke',
  VirtualSecurityTransfer24: 'stroke',
  Withdraw12: 'stroke',
  Withdraw16: 'stroke',
  Withdraw24: 'stroke',
  Buy12: 'stroke',
  Buy16: 'stroke',
  Buy24: 'stroke',
  Expense12: 'stroke',
  Expense16: 'stroke',
  Expense24: 'stroke',
  Margin12: 'stroke',
  Margin16: 'stroke',
  Margin24: 'stroke',
  Subscribe12: 'stroke',
  Subscribe16: 'stroke',
  Subscribe24: 'stroke',
  Unsubscribe12: 'stroke',
  Unsubscribe16: 'stroke',
  Unsubscribe24: 'stroke',
  ArrowRightSharpCircle24: 'stroke',
  ArrowRightSharpCircle20: 'stroke',
  ArrowRightSharpCircle16: 'stroke',
  ArrowRightSharpCircle12: 'stroke',
  ArrowRightSharpCircleCrossed24: 'stroke',
  ArrowRightSharpCircleCrossed20: 'stroke',
  ArrowRightSharpCircleCrossed16: 'stroke',
  ArrowRightSharpCircleCrossed12: 'stroke',
};
